import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./detail.css";
import AwesomeSlider from "react-awesome-slider";
import 'react-awesome-slider/dist/styles.css';

//my components
import Navbar from "../Navbarscreen/Navbar";
import Footer from "../Footerscreen/Footer";

import Aos from "aos";
import "aos/dist/aos.css";
const hiddenButtonIds = ["Others","Restaurant"];

const Detail = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const sendCardData = (parentObject) => {
        navigate("/form", { state: { data: parentObject } });
    };

    const navigate = useNavigate();

    

    const location = useLocation();
    const { data } = location.state;
    const [showAll, setShowAll] = useState(false);
    //const terms = [data.terms1, data.terms2, data.terms3, data.terms4, data.terms5, data.terms6, data.terms7, data.terms8, data.terms9, data.terms10, data.terms11, data.terms12, data.terms14, data.terms15, data.terms16, data.terms17, data.terms18, data.terms19, data.terms20, data.terms21, data.terms22, data.terms22, data.terms23, data.terms24, data.terms25].filter(term => term !== undefined);
    const terms = [data.terms1, data.terms2, data.terms3, data.terms4, data.terms5, data.terms6, data.terms7, data.terms8, data.terms9, data.terms10, data.terms11, data.terms12, data.terms14, data.terms15, data.terms16, data.terms17, data.terms18, data.terms19, data.terms20, data.terms21, data.terms22, data.terms23, data.terms24, data.terms25].filter(term => term !== undefined);
    
    const termsToShow = showAll ? terms : terms.slice(0, 5);

    const gotoHome = () => {
        navigate("/");
    };

    return (
        <>
            <Navbar />
            <section data-aos="fade-up" className="detail section container">
                <div className="detailedView">
                    <button onClick={gotoHome} className="btn detailbtn">
                        Home
                    </button>
                    <div className="heading">
                        <div className="buttonabove">
                            <div className="DestTitle">{data.destTitle}</div>
                        </div>

                        {data.subType === "Resort" ? (
                            <div className="Chip">Resort</div>
                        ) : (
                            <></>
                        )}
                    </div>

                    <div className="screenchangeflex">
                        <div>
                            <AwesomeSlider bullets={false}>
                                <div data-src={data.img} />
                                <div data-src={data.img2} />
                                <div data-src={data.img3} />
                            </AwesomeSlider>

                            <div className="descIdent"> {data.Description}</div>

                            {data.type !== "Safari" &&
                            data.type !== "Safari" ? (
                                <div className="pinLocation">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="pin"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <div className="location"> 
                                        
                                        <a href= {data.Map} target="_blank" rel="noopener noreferrer">
                                        {data.Location}
                                            </a>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            

                            {data.Website && (<div className="pinLocation">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="pin"
                                >
                                    <path d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z" />
                                </svg>
                                <a
                                    href={data.Website}
                                    target="_blank"
                                    className="location"
                                    rel="noreferrer"
                                >
                                    {data.Website}
                                </a>
                            </div>)}
                        </div>

                        <div className="sidebar">
                            <div className="OfferMainDiv">
                                <div className="">
                                    <div className="offerdata">
                                        {data.Offer1}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer2}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer3}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer4}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer5}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer6}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer7}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer8}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer9}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer10}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer11}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer12}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer13}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer14}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer15}
                                    </div>
                                    <div className="offerdata">
                                        {data.Offer16}
                                    </div>
                                </div>
                            </div>

                            <div className="OfferMainDiv">
                                                <div className="termsHeader">Terms and Conditions</div> 
                                                <div>
                                                    {termsToShow.map((term, index) => (
                                                        <div key={index} className="offerdata">
                                                            {term}
                                                        </div>
                                                    ))}
                                                </div>

                                                {terms.length > 5 && (
                                                    <button className="seemore" onClick={() => setShowAll(!showAll)}>
                                                        {showAll ? 'See Less' : 'See More'}
                                                    </button>
                                                )}
                                            </div>
                               
                            <div
                                className={
                                    data.blackoutPeriod !== "" ||
                                    data.stayPeriod !== ""
                                        ? "displaycard2"
                                        : "displaycard"
                                }
                            >
                                <div>
                                    <div>Offer {data.Period}</div>

                                    {data.stayPeriod !== "" ? (
                                        <div>
                                            Stay Period: {data.stayPeriod}
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    {data.blackoutPeriod !== "" ? (
                                        <div>
                                            Blackout Period:{" "}
                                            {data.blackoutPeriod}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            {hiddenButtonIds.indexOf(data.type) === -1 && (
                                <button
                                    onClick={() => sendCardData(data)}
                                    className="flex detailsbtn btnnewpage"
                                >
                                    Get offer
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Detail;
