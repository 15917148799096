/* import cocoonMaldives from "../../Assets/Cocoon Maldives/resized cocoon (1).png";
import cocoonMaldives2 from "../../Assets/Cocoon Maldives/resized cocoon (2).png";
import cocoonMaldives3 from "../../Assets/Cocoon Maldives/resized cocoon (3).png";
import youandme from "../../Assets/Cocoon_you&me/new (1).png";
import youandme2 from "../../Assets/Cocoon_you&me/new (2).png";
import youandme3 from "../../Assets/Cocoon_you&me/new (3).png"; */

import cocoBoduHithi from "../../Assets/Coco Bodu hithi/resized coco bodu hithi (1).jpg";
import cocoBoduHithi2 from "../../Assets/Coco Bodu hithi/resizedd (2).jpg";
import cocoBoduHithi3 from "../../Assets/Coco Bodu hithi/resizedd (3).jpg";
import cocoPalmDhuniKolhu from "../../Assets/Coco Palm Dhuni Kolhu/Coco-PalmDK-Landing.png";
import cocoPalmDhuniKolhu2 from "../../Assets/Coco Palm Dhuni Kolhu/CocoPalmDhuniKolhu-Resort.png";
import cocoPalmDhuniKolhu3 from "../../Assets/Coco Palm Dhuni Kolhu/CocoPalmDhuniKolhuHome1.png";
import furaveriMaldives from "../../Assets/Furaveri maldives/resized furaveri (1).jpg";
import furaveriMaldives2 from "../../Assets/Furaveri maldives/resized furaveri (2).jpg";
import furaveriMaldives3 from "../../Assets/Furaveri maldives/resized furaveri (3).jpg";
import hardRockHotel from "../../Assets/Hard Rock Hotel Maldives/resized hard rock (1).jpg";
import hardRockHotel2 from "../../Assets/Hard Rock Hotel Maldives/resized hard rock (2).jpg";
import hardRockHotel3 from "../../Assets/Hard Rock Hotel Maldives/resized hard rock (3).jpg";
import holidayInn from "../../Assets/Holiday Inn Kandooma/resized HIRKM -Main Pool 2 (1).jpg";
import holidayInn2 from "../../Assets/Holiday Inn Kandooma/resized holiday inn (1).jpg";
import holidayInn3 from "../../Assets/Holiday Inn Kandooma/resized holiday inn (2).jpg";
import hurawalhi from "../../Assets/Hurawalhi Island Resort/new (11).jpg";
import hurawalhi2 from "../../Assets/Hurawalhi Island Resort/new (12).jpg";
import hurawalhi3 from "../../Assets/Hurawalhi Island Resort/new (13).jpg";
import InnahuraMaldives from "../../Assets/Innahura Maldives Resort/new (7).jpg";
import InnahuraMaldives2 from "../../Assets/Innahura Maldives Resort/new (8).jpg";
import InnahuraMaldives3 from "../../Assets/Innahura Maldives Resort/new (9).jpg";
import kagiMaldives from "../../Assets/Kagi Maldives Spa Island/new (16).jpg";
import kagiMaldives2 from "../../Assets/Kagi Maldives Spa Island/new (5).jpg";
import kagiMaldives3 from "../../Assets/Kagi Maldives Spa Island/new (6).jpg";
import komandooMaldives from "../../Assets/Komandoo Island Resort & Spa/new (1).jpg";
import komandooMaldives2 from "../../Assets/Komandoo Island Resort & Spa/new (2).jpg";
import komandooMaldives3 from "../../Assets/Komandoo Island Resort & Spa/new (4).jpg";
import KureduIslanResort from "../../Assets/Kuredu Island Resort & Spa/new (3).jpg";
import KureduIslanResort2 from "../../Assets/Kuredu Island Resort & Spa/new (4).jpg";
import KureduIslanResort3 from "../../Assets/Kuredu Island Resort & Spa/new (6).jpg";
import { default as MeeruIslandResort, default as MeeruIslandResort3 } from "../../Assets/Meeru Island Resort & Spa/new (2).jpg";
import MeeruIslandResort2 from "../../Assets/Meeru Island Resort & Spa/new (4).jpg";
import OutrigerMaldivesMaafushi from "../../Assets/Outrigger Maldives Maafushivaru Resort/new (1).jpg";
import OutrigerMaldivesMaafushi2 from "../../Assets/Outrigger Maldives Maafushivaru Resort/new (2).jpg";
import OutrigerMaldivesMaafushi3 from "../../Assets/Outrigger Maldives Maafushivaru Resort/new (3).jpg";
import vilamendhoo from "../../Assets/Vilamendhoo Island Resort & Spa/new (10).jpg";
import vilamendhoo2 from "../../Assets/Vilamendhoo Island Resort & Spa/new (6).jpg";
import vilamendhoo3 from "../../Assets/Vilamendhoo Island Resort & Spa/new (8).jpg";
/* import thundufushi from "../../Assets/Thundufushi/new (1).jpg";
import thundufushi2 from "../../Assets/Thundufushi/new (2).jpg";
import thundufushi3 from "../../Assets/Thundufushi/new (4).jpg";
import athuruga from "../../Assets/Athuruga/resized 04.jpg";
import athuruga2 from "../../Assets/Athuruga/resized athuruga (2).jpg";
import athuruga3 from "../../Assets/Athuruga/resized athuruga (3).jpg"; 
import { default as acquaBlue, default as acquaBlue3 } from "../../Assets/Acqua Blu Rasdhoo/Capture.png";
import acquaBlue2 from "../../Assets/Acqua Blu Rasdhoo/beach.png";
import adaaranClubRannalhi from "../../Assets/Adaaran Club Rannalhi/RESIZED adaaran rannalhi (3).jpg";
import adaaranClubRannalhi2 from "../../Assets/Adaaran Club Rannalhi/resized (3).jpg";
import adaaranClubRannalhi3 from "../../Assets/Adaaran Club Rannalhi/resized rannalhi (1).jpg";
import adaaranPrestige from "../../Assets/Adaaran Prestige Vadoo/resized 001 (1).jpg";
import adaaranPrestige2 from "../../Assets/Adaaran Prestige Vadoo/resized 01 (2).jpg";
import adaaranPrestige3 from "../../Assets/Adaaran Prestige Vadoo/resized 01 (3).jpg";
import adaaranSelectMeedhupparu from "../../Assets/Adaaran Select Meedhupparu/resized meedh (1).jpg";
import adaaranSelectMeedhupparu2 from "../../Assets/Adaaran Select Meedhupparu/resized meedhupparu (2).jpg";
import adaaranSelectMeedhupparu3 from "../../Assets/Adaaran Select Meedhupparu/resized meedhupparu (3).jpg";
import adaaranSelectHudhuran from "../../Assets/Adaaran select hudhuran fushi/resized 02 (2).jpg";
import adaaranSelectHudhuran2 from "../../Assets/Adaaran select hudhuran fushi/resized 03 (1).jpg";
import adaaranSelectHudhuran3 from "../../Assets/Adaaran select hudhuran fushi/resized 03 (2).jpg";
import azure from "../../Assets/Azur/MicrosoftTeams-image (4).png";
import azure2 from "../../Assets/Azur/MicrosoftTeams-image (5).png";
import azure3 from "../../Assets/Azur/MicrosoftTeams-image (6).png";
import ClubGabbiano from "../../Assets/Club Gabbiano/resized club (1).jpg";
import ClubGabbiano2 from "../../Assets/Club Gabbiano/resized club (2).jpg";*/
import ClubGabbiano3 from "../../Assets/Club Gabbiano/resized gabbiano (1).jpg";
import canopus from "../../Assets/Dhinasha/resized dhinasha (2).jpg";
import canopus2 from "../../Assets/Dhinasha/resized dhinasha (3).jpg";
import canopus3 from "../../Assets/Dhinasha/resized dhinasha 01 (1).jpg";
import { default as dhiraagu, default as dhiraagu2, default as dhiraagu3 } from "../../Assets/Dhiraagu/Dhiraagu/dhiraagu.jpg";
import heritance from "../../Assets/Heritance Aaarah/resized aarah 01 (2).jpg";
import heritance2 from "../../Assets/Heritance Aaarah/resized heritance (1).jpg";
import heritance3 from "../../Assets/Heritance Aaarah/resized resort 01 (2).jpg";
import hightide from "../../Assets/High Tide/MicrosoftTeams-image (4).png";
import hightide2 from "../../Assets/High Tide/MicrosoftTeams-image (5).png";
import hightide3 from "../../Assets/High Tide/MicrosoftTeams-image (6).png";
import oneonInn from "../../Assets/Hotelonein/onein1.jpg";
import oneonInn2 from "../../Assets/Hotelonein/onein2.jpg";
import oneonInn3 from "../../Assets/Hotelonein/onein3.jpg";
import jenskitchen from "../../Assets/Jens Kitchen/328625970.jpg";
import jenskitchen2 from "../../Assets/Jens Kitchen/328626103.jpg";
import jenskitchen3 from "../../Assets/Jens Kitchen/jen-s-kitchen-on-the1.jpg";
import kcafe from "../../Assets/K Cafe/120ccb4193a5ce0a0af9604c1c782a69.jpg";
import { default as kcafe2, default as kcafe3 } from "../../Assets/K Cafe/Mookai-Suites-Male-Exterior.jpg";
import kethi from "../../Assets/Kethi/new (1).jpg";
import kethi2 from "../../Assets/Kethi/new (2).png";
import kethi3 from "../../Assets/Kethi/new (3).png";
import kanjiMaldives from "../../Assets/Khanji Maldives/new (1).jpg";
import kanjiMaldives2 from "../../Assets/Khanji Maldives/new (2).jpg";
import kanjiMaldives3 from "../../Assets/Khanji Maldives/new (3).jpg";
import kihaBeach from "../../Assets/Kiha Beach/new (1).jpg";
import kihaBeach2 from "../../Assets/Kiha Beach/new (3).jpg";
import kihaBeach3 from "../../Assets/Kiha Beach/new (6).jpg";
import lime from "../../Assets/Lime/lime-restaurant-jen-hotel1.jpg";
import lime2 from "../../Assets/Lime/lime-restaurant-jen-hotel2.jpg";
import lime3 from "../../Assets/Lime/lime-restaurant-jen-hotel3.jpg";
import macl from "../../Assets/MACL/MACL/macl-1.png";
import macl2 from "../../Assets/MACL/MACL/macl-2.png";
import macl3 from "../../Assets/MACL/MACL/macl-3.png";
import madivaruKro from "../../Assets/Madivaru Kro/new (1).jpg";
import madivaruKro2 from "../../Assets/Madivaru Kro/new (3).jpg";
import madivaruKro3 from "../../Assets/Madivaru Kro/new (4).jpg";
import maldivesBoatCLub from "../../Assets/Maldives Boat Club (Adora)/new (4).jpg";
import maldivesBoatCLub2 from "../../Assets/Maldives Boat Club (Adora)/new (5).jpg";
import maldivesBoatCLub3 from "../../Assets/Maldives Boat Club (Adora)/new (6).jpg";
import { default as ooredoo, default as ooredoo2, default as ooredoo3 } from "../../Assets/Ooredoo/Ooredoo/ooredoo.png";
import reveriesMaldives from "../../Assets/Reveries Maldives/new (1).jpg";
import reveriesMaldives2 from "../../Assets/Reveries Maldives/new (11).jpg";
import reveriesMaldives3 from "../../Assets/Reveries Maldives/new (2).jpg";
import shallowLagoonRasdhoo from "../../Assets/Shallow Lagoon Rasdhoo/new (10).jpg";
import shallowLagoonRasdhoo2 from "../../Assets/Shallow Lagoon Rasdhoo/new (11).jpg";
import shallowLagoonRasdhoo3 from "../../Assets/Shallow Lagoon Rasdhoo/new (12).jpg";
import shallowLagoonSunset from "../../Assets/Shallow Lagoon SunSet/new (1).jpg";
import shallowLagoonSunset2 from "../../Assets/Shallow Lagoon SunSet/new (2).jpg";
import shallowLagoonSunset3 from "../../Assets/Shallow Lagoon SunSet/new (3).jpg";
import solite from "../../Assets/Solite Restaurant/new (1).jpg";
import solite2 from "../../Assets/Solite Restaurant/new (2).jpg";
import solite3 from "../../Assets/Solite Restaurant/new (3).jpg";
import theMureed from "../../Assets/The Mureed/new (1).jpg";
import theMureed2 from "../../Assets/The Mureed/new (2).jpg";
import theMureed3 from "../../Assets/The Mureed/new (3).jpg";
// import ministryofcrab from "../../Assets/Ministry of Crab/Butter-Crab-1.jpg";
// import ministryofcrab2 from "../../Assets/Ministry of Crab/ministry-of-crab-maldives.jpg";
// import ministryofcrab3 from "../../Assets/Ministry of Crab/The-Marina-at-CROSSROADS-1-scaled.jpg";
// import carnediem from "../../Assets/Carne Diem/2021-07-12.jpg";
// import carnediem2 from "../../Assets/Carne Diem/Carne-Diem-62.jpg";
// import carnediem3 from "../../Assets/Carne Diem/The-Marina-at-CROSSROADS-1-scaled.jpg";
// import nihonbashi from "../../Assets/Nihonbashi Blue/2020-01-03-14.42.13.jpg";
// import nihonbashi2 from "../../Assets/Nihonbashi Blue/IMG_8396-scaled.jpg";
// import nihonbashi3 from "../../Assets/Nihonbashi Blue/The-Marina-at-CROSSROADS-1-scaled.jpg";
//import belleamie from "../../Assets/Belle Amie Bistro/belle-amie-bistro-in (1).jpg";
//import belleamie2 from "../../Assets/Belle Amie Bistro/belle-amie-bistro-in.jpg";
//import belleamie3 from "../../Assets/Belle Amie Bistro/l_2018_09_04_073745_1i8l5y4regjumnzcx3bp2v6shkwo97tqdfa_EN_.jpg";
//import tourinn3 from "../../Assets/Tour Inn/";


const Data = [
    /* {
        ID: 1001,
        img: cocoonMaldives,
        img2: cocoonMaldives2,
        img3: cocoonMaldives3,
        destTitle: "Cocoon Maldives",
        Description:
            "Italian design meets Maldivian beauty. Secluded in Lhaviyani Atoll, just 30 minutes from Malé by seaplane.It is an ideal destination to explore the wonders of the Maldives.",
        Location: "Cocoon Maldives, Lhaviyani Atoll",
        Website: "https://www.cocoonmaldives.com/",
        shortDesc: "Enjoy your stay with complimentary cruise",
        Propertyemail: "booking@cocoonmaldives.com",
        Rating: 5,
        Period: "Valid till 31st October 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Complimentary Dolphin cruise excursion per person once during the stay (minimun 5 nights)",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• Offer is applicable for stays completed within the offer period",
        terms2: "• Stays in all room types are eligible for this offer",
        terms3: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms4: "• Additional terms and conditions of the property will apply",
        terms5: "• No minimum spending. however, must stay for 5 nights to avail of the offer",
        terms6: "• Offer is valid for once during the stay. However, allowed for multiple stays throughout the offer period",
        terms7: "• No blackout dates and last day to raise query for this offer is 31st October 2023",
        terms8: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
        terms9: "• Discount is not applicable for extras consumed at the property",
        terms10: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms11: "• For more information or for further assistance with your booking, contact us directly by using website link, https://www.cocoonmaldives.com and booking@youandmemaldives.com",
        terms12: "",
        terms13: "",
        terms14: "",
        terms15: "",
        terms16: "",
        terms17: "",
        terms18: "",
        terms19: "",
        terms20: "",
        terms21: "",
        terms22: "",
        terms23: "",
        terms24: "",
        terms25: "",
    },
    {
        ID: 1002,
        img: youandme,
        img2: youandme2,
        img3: youandme3,
        destTitle: "You & Me by Cocoon",
        Description:
            "Indulge in ultimate luxury and romance. This exclusive resort offers 5-star accommodations, ensuring an unforgettable and indulgent getaway for honeymooners and couples. This is an Age-restricted retreat.",
        Location: "You & Me by Cocoon, Raa Atoll",
        Website: "https://www.youandmemaldives.com/",
        shortDesc: "Enjoy your stay with complimentary cruise",
        Propertyemail: "booking@youandmemaldives.com",
        Rating: 5,
        Period: "Valid till 31st October 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Complimentary Dolphin cruise excursion per person once during the stay (minimun 5 nights)",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• Offer is applicable for stays completed within the offer period",
        terms2: "• Stays in all room types are eligible for this offer",
        terms3: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms4: "• Additional terms and conditions of the property will apply",
        terms5: "• No minimum spending. However, must stay for 5 nights to avail of the offer",
        terms6: "• Offer is valid for once during the stay. However, allowed for multiple stays throughout the offer period",
        terms7: "• No blackout dates and last day to raise query for this offer is 31st October 2023",
        terms8: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
        terms9: "• Discount is not applicable for extras consumed at the property",
        terms10: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies ",
		terms11: "• For more information or for further assistance with your booking, contact us directly by using website link, https://www.cocoonmaldives.com and booking@youandmemaldives.com",
    }, 
    /*{
        ID: 1003,
        img: cocoPalmDhuniKolhu,
        img2: cocoPalmDhuniKolhu2,
        img3: cocoPalmDhuniKolhu3,
        destTitle: "Coco Palm Dhuni Kolhu",
        Description:
            "Blending traditional architecture with passionate flair. Discover Coco Palm Dhuni Kolhu in Baa Atoll, offering stunning villas, exquisite dining, and a scenic seaplane journey for a truly luxurious experience.",
        Location: "Coco Palm Dhuni Kolhu, Baa Atoll",
        Website: "https://cococollection.com/en/palm_dk",
        shortDesc: "Enjoy by earning a free night!",
        Propertyemail: "reservations@cococollection.com",
        Rating: 5,
        Period: "Valid till 20th December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 4 nights and get 1 night free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• No blackout dates and last day to raise query for this offer is 10th December 2023",
        terms4: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
        terms5: "• Discount is not applicable for extras consumed at the property",
        terms6: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms7: "• No minimum spending. however, must stay for 4 nights to avail of the offer ",
        terms8: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
		terms9: "• For more information or for further assistance with your booking, E-mail: reservations@cococollection.com",
    },
    {
        ID: 1004,
        destTitle: "Coco Bodu Hithi",
        img: cocoBoduHithi,
        img2: cocoBoduHithi2,
        img3: cocoBoduHithi3,
        Description:
            "Experience paradise at Coco Bodu Hithi, a serene Maldivian resort in North Malé Atoll. With pristine beaches, captivating House Reef, and a range of accommodations, your tropical getaway awaits.",
        Location: "Coco Bodu Hithi, North Male' Atoll",
        Website: "https://cococollection.com/en/bodu_hithi",
        shortDesc: "Enjoy by earning a free night!",
        Propertyemail: "booking@cocoonmaldives.com",
        Rating: 5,
        Period: "Valid till 20th  December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 4 nights and get 1 night free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• No blackout dates and last day to raise query for this offer is 10th December 2023",
        terms4: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
        terms5: "• Discount is not applicable for extras consumed at the property",
        terms6: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms7: "• No minimum spending. however, must stay for 4 nights to avail of the offer ",
        terms8: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
		terms9: "• For more information or for further assistance with your booking, E-mail: reservations@cococollection.com",
    },
    {
        ID: 1005,
        destTitle: "Furaveri Maldives",
        img: furaveriMaldives,
        img2: furaveriMaldives2,
        img3: furaveriMaldives3,
        Description:
            "Furaveri Maldives is a 5 star deluxe property. An island with an intriguing history, and rich with life, as evident in the white herons you meet through out the island, the vibrant screwpine trees, the frequent turtles in our reef and the gentle mantas that bless us with occasional visits.",
        Location: "Furaveri Maldives, Raa Atoll",
        Website: "https://furaveri.com/",
        shortDesc: "Exclusive offer to stay more and pay less",
        Propertyemail: "reservations@furaveri.com",
        Rating: 5,
        Period: "Valid till 10th January 2024",
        stayPeriod: "",
        blackoutPeriod: "23rd December 2023 to 07th January 2024",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "yes",
        Offer1: "Stay for 7 nights and pay for 6 nights, Stay for 10 nights or more and get 2 nights free with breakfast from the Jaafaiy Restaurant",
        Offer2: "One free night fishing per person, per stay",
        Offer3: "10% discount from all diving courses and equipment rentals",
        Offer4: "10% discount from all spa treatments",
        Offer5: "Free snorkeling equipments during the stay",
        Offer7: "Complimentary fruit plate",
        Offer8: "A bottle of wine upon arrival in the villa",
        Offer9: "Honeymoon bed decoration on arrival",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Rates are quoted in US Dollars per room, per night on BB rates and include GST and Service Charge",
        terms4: "• Government compulsory environmental tax, currently USD 6.00 per head per night, will be levied",
        terms5: "• Cancellations received within 14 days prior to the date of arrival: 100% of the total of room and meal package will be Charged",
        terms6: "• Cancellations received within 60 days prior to the date of arrival: 100% of the total of room on BB will be charged",
        terms7: "• This offer is applicable for stays between 1 March 2023 to 10 January 2024, both dates included",
        terms8: "• This offer is not applicable for stays between 23rd December 2024 and 07th January 2024",
		terms9: "• Offer can be availed multiple times within the offer period and cannot be combined with any other offers",
		terms10: "• Reservation must be made 72 hours in advance",
		terms11: "• Cancellations received within 14 days prior to the date of arrival: 100% of the total of room and meal package will be Charged",
		terms12: "• For more information or for further assistance with your booking, E-mail: sales@furaveri.com",
    },
    {
        ID: 1006,
        destTitle: "Outrigger Maldives Maafushivaru Resort",
        img: OutrigerMaldivesMaafushi,
        img2: OutrigerMaldivesMaafushi2,
        img3: OutrigerMaldivesMaafushi3,
        Description:
            "Escape to Outrigger Maldives Maafushivaru Resort, a perfect blend of Maldivian tradition and contemporary luxury in the South Ari Atoll. Unforgettable memories await couples, families, and all seeking an extraordinary island getaway.",
        Location: "Outrigger Maldives Maafushivaru Resort, South Ari Atoll",
        Website: "https://www.outrigger.com/",
        shortDesc: "Save up to 25% with complimentary meals and spa discounts",
        Propertyemail: "maldives.maafushivaru@outrigger.com",
        Rating: 5,
        Period: "Valid till 31st January 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "yes",
        Offer1: "Complimentary meals, exclusive Spa discounts and early check in - Save up to 25%",
        Offer2: "Enjoy breakfasts and dinner daily for 2 adults and a 10% discount on Navasana Spa",
        Offer3: "Includes Breakfast and Dinner in Buffet restaurant daily",
        Offer4: "Specialty dining options incur a surcharge",
        Offer5: "Navasana Spa Treatments must be pre-booked before arrival to secure 10% discount",
        Offer6: "Early check-in subject to availability",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• A refundable deposit of one night's room rate plus taxes is required at the time of booking",
        terms4: "• Cancellations made within 21 days prior to arrival are subject to a charge of 100% room and tax of the original booking",
    },
    {
        ID: 1007,
        destTitle: "Meeru Island Resort & Spa",
        img: MeeruIslandResort,
        img2: MeeruIslandResort2,
        img3: MeeruIslandResort3,
        Description:
            "With pristine beaches, turquoise lagoons, and vibrant coral reefs, this multiple-award-winning resort offers top-notch, all-inclusive facilities and personalized service. Experience a comfortable and welcoming home away from home, perfect for families seeking an unforgettable getaway.",
        Location: "Meeru Island Resort & Spa, North Male' Atoll",
        Website: "https://www.meeru.com/",
        shortDesc: "Save up to 20% with complimentary meals and spa discounts",
        Propertyemail: "reservations@meeru.com",
        Rating: 5,
        Period: "Valid till March 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 7 nights and get 2 nights free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• For free nights, Green tax of USD 6.00 per person per night is payable direct to the resort",
        terms4: "• Rooms are subject to availability upon guest request",
        terms5: "• Rates will be quoted based on selling rates published on the resort website",
        terms6: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
        terms7: "• No blackout dates, last day to raise query for this offer is 23rd December 2023",
        terms8: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms9: "• No minimum spending. However, must stay for 7 nights to avail of the offer",
        terms10: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms11: "• For more information or for further assistance with your booking, contact E-mail: reservations@meeru.com  / Telephone: +960 6643157",
    },
    {
        ID: 1008,
        destTitle: "Kuredu Island Resort & Spa",
        img: KureduIslanResort,
        img2: KureduIslanResort2,
        img3: KureduIslanResort3,
        Description:
            "Experience Maldives' popular gem, Kuredu Resort. Choose from diverse accommodations and all-inclusive packages. Enjoy restaurants, bars, shops, activities, and including the fisrt golf course in Maldives for a memorable getaway.",
        Location: "Kuredu Island Resort & Spa, Lhaviyani Atoll",
        Website: "https://www.kuredu.com/",
        shortDesc: "Enjoy the exclusive offer to earn 2 free nights!",
        Propertyemail: "info@kuredu.com",
        Rating: 4,
        Period: "Valid till March 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 7 nights and get 2 nights free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• For free nights, Green tax of USD 6.00 per person per night is payable direct to the resort",
        terms4: "• Rooms are subject to availability upon guest request",
        terms5: "• Rates will be quoted based on selling rates published on the resort website",
        terms6: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
        terms7: "• No blackout dates, last day to raise query for this offer is 23rd December 2023",
        terms8: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms9: "• No minimum spending. However, must stay for 7 nights to avail of the offer",
        terms10: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms11: "• For more information or for further assistance with your booking, contact E-mail: reservations@kuredu.com  / Telephone: +960 6623200",
    },
    {
        ID: 1009,
        destTitle: "Innahura Maldives Resort",
        img: InnahuraMaldives,
        img2: InnahuraMaldives2,
        img3: InnahuraMaldives3,
        Description:
            "Embrace the essence of Maldives at Innahura, a resort in the south-east Lhaviyani Atoll. With a blend of modern facilities and authentic experiences, it's the ideal destination for sunseekers of all ages, where relaxation and savoring life's pleasures come naturally.",
        Location: "Innahura Maldives Resort, Lhaviyani Atoll",
        Website: "https://www.innahura.com/",
        shortDesc: "Enjoy the exclusive offer to earn 2 free nights!",
        Propertyemail: "reservations@innahura.com",
        Rating: 4,
        Period: "Valid till March 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 7 nights and get 2 nights free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• For free nights, Green tax of USD 6.00 per person per night is payable direct to the resort",
        terms4: "• Rooms are subject to availability upon guest request",
        terms5: "• Rates will be quoted based on selling rates published on the resort website",
        terms6: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
        terms7: "• No blackout dates, last day to raise query for this offer is 23rd December 2023",
        terms8: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms9: "• No minimum spending. However, must stay for 7 nights to avail of the offer",
        terms10: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms11: "• For more information or for further assistance with your booking, contact E-mail: reservations@innahura.com  / Telephone: +960 6623298",
    },
    {
        ID: 1010,
        destTitle: "Komandoo Island Resort & Spa",
        img: komandooMaldives,
        img2: komandooMaldives2,
        img3: komandooMaldives3,
        Description:
            "Experience romance at Komandoo, an exclusive adults-only resort blending Maldivian beauty with intimate charm. With award-winning service and a variety of leisure activities, this tranquil retreat is perfect for honeymoons, romantic escapes, or getaways with friends.",
        Location: "Komandoo Island Resort & Spa, Lhaviyani Atoll",
        Website: "https://www.komandoo.com/",
        shortDesc: "Enjoy the exclusive offer to earn 2 free nights!",
        Propertyemail: "reservations@komandoo.com",
        Rating: 4,
        Period: "Valid till March 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 7 nights and get 2 nights free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• For free nights, Green tax of USD 6.00 per person per night is payable direct to the resort",
        terms4: "• Rooms are subject to availability upon guest request",
        terms5: "• Rates will be quoted based on selling rates published on the resort website",
        terms6: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
        terms7: "• No blackout dates, last day to raise query for this offer is 23rd December 2023",
        terms8: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms9: "• No minimum spending. However, must stay for 7 nights to avail of the offer",
        terms10: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms11: "• For more information or for further assistance with your booking, contact E-mail: reservations@komandoo.com  / Telephone: +960 6623299",
    },
    {
        ID: 1011,
        destTitle: "Kagi Maldives Spa Island",
        img: kagiMaldives,
        img2: kagiMaldives2,
        img3: kagiMaldives3,
        Description:
            "Indulge in pure luxury at Kagi Maldives Spa Island, an intimate all-pool villa resort in North Malé Atoll. Immerse yourself in curated wellness experiences amidst breathtaking beauty. Discover an enchanting oasis of balance and authenticity.",
        Location: "Kagi Maldives Spa Island, North Male' Atoll",
        Website: "https://kagimaldives.com/",
        shortDesc: "Enjoy the exclusive offer to earn 2 free nights",
        Propertyemail: "reservations@kagimaldives.com",
        Rating: 5,
        Period: "Valid till March 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 7 nights and get 2 nights free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• For free nights, Green tax of USD 6.00 per person per night is payable direct to the resort",
        terms4: "• Rooms are subject to availability upon guest request",
        terms5: "• Rates will be quoted based on selling rates published on the resort website",
        terms6: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
        terms7: "• No blackout dates, last day to raise query for this offer is 23rd December 2023",
        terms8: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms9: "• No minimum spending. However, must stay for 7 nights to avail of the offer",
        terms10: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms11: "• For more information or for further assistance with your booking, contact E-mail: reservations@kagimaldives.com  / Telephone: +960 664 0123",
    },
    {
        ID: 1012,
        destTitle: "Vilamendhoo Island Resort & Spa",
        img: vilamendhoo,
        img2: vilamendhoo2,
        img3: vilamendhoo3,
        Description:
            "With ‘One Island, One Resort’ concept that the Maldives is famous for, Vilamendhoo is the quintessential dive and snorkelling island adventure.",
        Location: "Vilamendhoo Island Resort & Spa, South Ari Atoll",
        Website: "https://www.vilamendhoo.com/",
        shortDesc: "Enjoy the exclusive offer to earn 2 free nights",
        Propertyemail: "reservations@vilamendhoo.com",
        Rating: 4,
        Period: "Valid till March 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 7 nights and get 2 nights free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• For free nights, Green tax of USD 6.00 per person per night is payable direct to the resort",
        terms4: "• Rooms are subject to availability upon guest request",
        terms5: "• Rates will be quoted based on selling rates published on the resort website",
        terms6: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
        terms7: "• No blackout dates, last day to raise query for this offer is 23rd December 2023",
        terms8: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms9: "• No minimum spending. However, must stay for 7 nights to avail of the offer",
        terms10: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms11: "• For more information or for further assistance with your booking, contact E-mail: reservations@vilamendhoo.com  / Telephone: +960 6680207",
    },
    {
        ID: 1013,
        destTitle: "Hurawalhi Island Resort",
        img: hurawalhi,
        img2: hurawalhi2,
        img3: hurawalhi3,
        Description:
            "Experience island bliss at Hurawalhi Island Resort, a culinary hotspot with the largest all-glass underwater restaurant. Celebrate life's special moments and create cherished memories in this enchanting destination.",
        Location: "Hurawalhi Island Resort, Lhaviyani Atoll",
        Website: "https://www.hurawalhi.com/",
        shortDesc: "Enjoy the exclusive offer to earn 2 free nights",
        Propertyemail: "reservations@hurawalhi.com",
        Rating: 5,
        Period: "Valid till March 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 7 nights and get 2 nights free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• For free nights, Green tax of USD 6.00 per person per night is payable direct to the resort",
        terms4: "• Rooms are subject to availability upon guest request",
        terms5: "• Rates will be quoted based on selling rates published on the resort website",
        terms6: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
        terms7: "• No blackout dates, last day to raise query for this offer is 23rd December 2023",
        terms8: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms9: "• No minimum spending. However, must stay for 7 nights to avail of the offer",
        terms10: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms11: "• For more information or for further assistance with your booking, contact E-mail: reservations@hurawalhi.com  / Telephone: +960 6626493",
    },
   /* {
        ID: 1014,
        destTitle: "Holiday Inn Kandooma",
        img: holidayInn,
        img2: holidayInn2,
        img3: holidayInn3,
        Description:
            "Your private island escape in the South Male atoll. Enjoy pristine beaches, superb diving, and a range of activities for families, including the Kandoo Kids' Club. Experience paradise just 40 minutes from the airport by boat.",
        Location: "Holiday Inn Kandooma, South Male' Atoll",
        Website: "https://maldives.holidayinnresorts.com/",
        shortDesc: "Enjoy the exclusive offer to earn 2 free nights",
        Propertyemail: "reservations.hikandooma@ihg.com",
        Rating: 4,
        Period: "Valid till March 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "yes",
        Offer1: "Stay 5 nights, Get 2 additional nights free",
        Offer2: "Complimentary Airport-Resort-Airport Speedboat Transfers",
        Offer3: "Complimentary Sundowner (choice of house mocktail or cocktail) at Sunset Deck & Bar on ONE night",
        Offer4: "Complimentary non-motorised watersports",
        Offer5: "15% off at Kandooma Spa by COMO Shambhala",
        Offer6: "15% off Scuba Diving",
        Offer7: "IHG Kids Eat & Stay FREE Program",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
		terms22: "• This offer can be availed for multiple stays throughout the offer period",
		terms23: "• No minimum spending, however must stay for 5 nights to avail of the offer",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Valid for new bookings made by 30 September 2023 for stays made through 31 January 2024",
        terms4: "• All reservations must be guaranteed with an American Express card that is valid throughout the entire stay",
        terms5: "• American Express cardholders must present an eligible American Express card upon check-in and must make payment with their eligible American Express card to enjoy the promotional rates or Best Flexible Rates at time of check-in will be applied",
        terms6: "• Rates quoted are the average rate per night after the complimentary night/s has been applied",
        terms7: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
        terms8: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no show policies",
        terms9: "• Deposit for first night stay is required at times of booking",
        terms10:
            "• First night cancellation penalty applies for cancellations made after booking confirmation",
        terms11:
            "• Promotional rates and rooms are strictly subject to availability, and advertised savings are based on Best Flexible Rates",
        terms12:
            "• Offers cannot be used in conjunction with any other promotion, discounts or packages and are non-exchangeable, transferrable or redeemable for cash",
        terms13:
            "• Please contact hotel for details prior to booking regarding occupancy policies relating to children as they vary depending on each room configuration",
        terms14:
            "• Maximum occupancy per room is two adults; additional guests may incur further charges",
        terms15:
            "• Breakfast is for a maximum of two adults per room, per day in all properties, unless special offers (Half-board/Full-board)",
        terms16:
            "• Blackout Dates apply. Please check direct with the property",
        terms17:
            "• Rates are exclusive of taxes, fees, service charge and prevailing government tax",
        terms18:
            "• Offer is not applicable to groups, corporate rates, tour operator, travel agency, group and packages, complimentary stays, industry rates, wholesaler and third-party rates",
        terms19:
            "• Discounts exclude dining, alcohol, mini bar, private dining, specialty dining, in-villa dining/room service, spa packages, activities or retail items, unless where specifically stipulated",
        terms20:
            "• American Express and Holiday Inn Resort Kandooma Maldives reserve the right to vary the Promotion and the terms and conditions applicable to the same or to withdraw or discontinue the Promotion without prior notice or liability to any party",
    },
	{
        ID: 1056,
        destTitle: "Holiday Inn Kandooma",
        img: holidayInn,
        img2: holidayInn2,
        img3: holidayInn3,
        Description:
            "Your private island escape in the South Male atoll. Enjoy pristine beaches, superb diving, and a range of activities for families, including the Kandoo Kids' Club. Experience paradise just 40 minutes from the airport by boat.",
        Location: "Holiday Inn Kandooma, South Male' Atoll",
        Website: "https://maldives.holidayinnresorts.com/",
        shortDesc: "Enjoy 20% discount on your stay",
        Propertyemail: "reservations.hikandooma@ihg.com",
        Rating: 4,
        Period: "Valid till 30th September 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "yes",
        Offer1: "Stay 3 nights and receive 20% off",
        Offer2: "50% off Airport-Resort-Airport Speedboat Transfers",
        Offer3: "Complimentary Sundowner (choice of house mocktail or cocktail) at Sunset Deck & Bar on ONE night",
        Offer4: "Complimentary non-motorised watersports",
        Offer5: "10% off at Kandooma Spa by COMO Shambhala",
        Offer6: "10% off Scuba Diving",
        Offer7: "IHG Kids Eat & Stay FREE Program",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
		terms22: "• This offer can be availed for multiple stays throughout the offer period",
		terms23: "• No minimum spending, however must stay for 5 nights to avail of the offer",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Valid for new bookings made by 30 September 2023 for stays made through 31 January 2024",
        terms4: "• All reservations must be guaranteed with an American Express card that is valid throughout the entire stay",
        terms5: "• American Express cardholders must present an eligible American Express card upon check-in and must make payment with their eligible American Express card to enjoy the promotional rates or Best Flexible Rates at time of check-in will be applied",
        terms6: "• Rates quoted are the average rate per night after the complimentary night/s has been applied",
        terms7: "• Reservation must be made 48 hours in advance and subject to availability at time of reservation",
        terms8: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no show policies",
        terms9: "• Deposit for first night stay is required at times of booking",
        terms10:
            "• First night cancellation penalty applies for cancellations made after booking confirmation",
        terms11:
            "• Promotional rates and rooms are strictly subject to availability, and advertised savings are based on Best Flexible Rates",
        terms12:
            "• Offers cannot be used in conjunction with any other promotion, discounts or packages and are non-exchangeable, transferrable or redeemable for cash",
        terms13:
            "• Please contact hotel for details prior to booking regarding occupancy policies relating to children as they vary depending on each room configuration",
        terms14:
            "• Maximum occupancy per room is two adults; additional guests may incur further charges",
        terms15:
            "• Breakfast is for a maximum of two adults per room, per day in all properties, unless special offers (Half-board/Full-board)",
        terms16:
            "• Blackout Dates apply. Please check direct with the property",
        terms17:
            "• Rates are exclusive of taxes, fees, service charge and prevailing government tax",
        terms18:
            "• Offer is not applicable to groups, corporate rates, tour operator, travel agency, group and packages, complimentary stays, industry rates, wholesaler and third-party rates",
        terms19:
            "• Discounts exclude dining, alcohol, mini bar, private dining, specialty dining, in-villa dining/room service, spa packages, activities or retail items, unless where specifically stipulated",
        terms20:
            "• American Express and Holiday Inn Resort Kandooma Maldives reserve the right to vary the Promotion and the terms and conditions applicable to the same or to withdraw or discontinue the Promotion without prior notice or liability to any party",
    },
    {
        ID: 1015,
        destTitle: "Hard Rock Hotel Maldives",
        img: hardRockHotel,
        img2: hardRockHotel2,
        img3: hardRockHotel3,
        Description:
            "Combining contemporary design with local culture, Enjoy amenities like The Sound of Your Stay music program, Rock Spa®, Roxity Kids Club®, and direct access to The Marina @ CROSSROADS Maldives. With diverse dining options and an unforgettable atmosphere, indulge in the ultimate experience.",
        Location: "Hard Rock Hotel Maldives, South Male' Atoll",
        Website: "https://www.hardrockhotels.com/maldives/",
        shortDesc:
            "Enjoy exclusive offer to earn free night and earn discounts",
        Propertyemail: "rsvn@hrhmaldives.com",
        Rating: 5,
        Period: "Valid till 20th December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 7 nights and pay for 6 nights",
        Offer2: "15% discount at Rock Spa",
        Offer3: "15% discount at Rock Shop",
        Offer4: "Free snacks & drinks in daily Sundown Ritual",
        Offer5: "Free use of kayak, pedal boat, & snorkeling equipment",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
    },*/
    // {
    //     ID: 1016,
    //     destTitle: "Diamonds Thudufushi",
    //     img: thundufushi,
    //     img2: thundufushi2,
    //     img3: thundufushi3,
    //     Description:
    //         "The ideal resort for an unforgettable journey in the Maldives. Dive with experts, explore with biologists, and indulge in a unique and unforgettable stay.",
    //     Location: "Diamonds Thudufushi Maldives, South Ari Atoll",
    //     Website: "https://thudufushibeach.diamondsresorts.com/",
    //     shortDesc: "Enjoy 25% discount on your stay",
    //     Propertyemail: "info.thudufushi@diamonds-resorts.com",
    //     Rating: 5,
    //     Period: "Book before 15th June 2023",
    //     stayPeriod: "01st August 2023 – 30th April 2024 (including 30th April ’24 Night)",
    //     blackoutPeriod: "",
    //     type: "Resort & Hotel",
    //     subType: "Resort",
    //     ManyOffers: "no",
    //     Offer1: "Secure a 25% discount on the room rate",
    //     Offer2: "",
    //     Offer3: "",
    //     Offer4: "",
    //     Offer5: "",
    //     Offer6: "",
    //     Offer7: "",
    //     Offer8: "",
    //     Offer9: "",
    //     Offer10: "",
    //     Offer11: "",
    //     Offer12: "",
    //     Offer13: "",
    //     Offer14: "",
    //     Offer15: "",
    //     Offer16: "",
    //     terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
    //     terms2: "• Additional terms and conditions of the property will apply",
    //     terms3: "• Offers are valid for all markets except Italy",
    //     terms4: "• Applicable for the single / DBL basis and adult extra bed (including child)",
    //     terms5: "• Applicable for Individual clients only (Not applicable to any group type reservations)",
    //     terms6: "• Not applicable to other Supplements and extras",
    //     terms7: "• All other conditions remain applicable as per contracted terms & conditions",
    //     terms8: "• This special offer is not retroactive (cancellation & rebooking under the same name will not be authorized)",
    //     terms9: "• Voyages Maldives reserves the right to stop the application of this offer at any time and without prior notice to the partner",
    // },
    // {
    //     ID: 1017,
    //     destTitle: "Diamonds Athuruga",
    //     img: athuruga,
    //     img2: athuruga2,
    //     img3: athuruga3,
    //     Description:
    //         "Experience the perfect Maldivian escape at Athuruga. Immerse yourself in excellence, discover enchanting coral reefs, and relax in total bliss with an unforgettable experience.",
    //     Location: "Diamonds Athuruga Maldives, South Ari Atoll",
    //     Website: "https://athurugabeach.diamondsresorts.com/",
    //     shortDesc: "Enjoy 25% discount on your stay",
    //     Propertyemail: "info.athuruga@diamonds-resorts.com",
    //     Rating: 5,
    //     Period: "Book before 15th June 2023",
    //     stayPeriod: "01st August 2023 – 30th April 2024 (including 30th April ’24 Night)",
    //     blackoutPeriod: "",
    //     type: "Resort & Hotel",
    //     subType: "Resort",
    //     ManyOffers: "no",
    //     Offer1: "Secure a 25% discount on the room rate",
    //     Offer2: "",
    //     Offer3: "",
    //     Offer4: "",
    //     Offer5: "",
    //     Offer6: "",
    //     Offer7: "",
    //     Offer8: "",
    //     Offer9: "",
    //     Offer10: "",
    //     Offer11: "",
    //     Offer12: "",
    //     Offer13: "",
    //     Offer14: "",
    //     Offer15: "",
    //     Offer16: "",
    //     terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
    //     terms2: "• Additional terms and conditions of the property will apply",
    //     terms3: "• Offers are valid for all markets except Italy",
    //     terms4: "• Applicable for the single / DBL basis and adult extra bed (including child)",
    //     terms5: "• Applicable for Individual clients only (Not applicable to any group type reservations)",
    //     terms6: "• Not applicable to other Supplements and extras",
    //     terms7: "• All other conditions remain applicable as per contracted terms & conditions",
    //     terms8: "• This special offer is not retroactive (cancellation & rebooking under the same name will not be authorized)",
    //     terms9: "• Voyages Maldives reserves the right to stop the application of this offer at any time and without prior notice to the partner",
    // },
    /*{
        ID: 1018,
        destTitle: "Heritance Aaarah",
        img: heritance,
        img2: heritance2,
        img3: heritance3,
        Description:
            "Indulge in unparalleled luxury at Heritance Aarah, the ultimate Maldivian retreat. Our commitment to responsible travel shines through as the first green rated resort in the Maldives. Join us in our eco-initiatives and experience an unforgettable adventure for both adventurers and romantics alike.",
        Location: "Heritance Aarah, Raa Atoll",
        Website: "https://www.heritancehotels.com/aarah/",
        shortDesc: "Enjoy exclusive offer to earn free night",
        Propertyemail: "res.aarah@heritancehotels.com",
        Rating: 5,
        Period: "Valid till 25th December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 6 nights and get 1 night free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
		terms18: "• This offer can be availed for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• The offer will be valid only for the Amex credit Cardholders during the aforementioned period of the offer",
        terms4: "• The discounted rate is applicable for bookings made during the offer period",
        terms5: "• Rates are inclusive of taxes (as imposed by the government)",
        terms6: "• Full payment will collect 7 days prior to arrival",
        terms7: "• Any booking unable to secure payment method will cancel after informing the guest with in 24hours",
        terms8: "• Discount is not applicable for extras consumed at the property",
        terms9: "• The discount given is based on the BAR rate as determined by the company and not on rates that may be published on other platforms",
        terms10:
            "• Cardholder must be present for the duration of the booked stay period for the offer to be valid",
        terms11:
            "• Cardholder is required to submit the Amex credit card used to make the reservation upon arrival at the Hotel for verification purposes",
        terms12:
            "• Offer will not be valid if referred to at the time of check-in or checkout",
        terms13:
            "• Please note the offer is not applicable for Corporate outings and day outings, Gift vouchers, Pre-confirmed bookings, Reservations made through any other platforms, online or offline and cannot be combined with other offers",
		terms14: "• Blackout Dates apply, from 26th December 2023 to 31st December 2023. However, last day to raise query for this offer is 20th December 2023",
        terms15: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms16: "• No minimum spending. However, must stay for 6 nights to avail of the offer ",
        terms17: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms19: "• For more information or for further assistance with your booking, contact us directly by using website link, https://www.heritancehotels.com/aarah",
    },
    {
        ID: 1019,
        destTitle: "Adaaran Prestige Vadoo",
        img: adaaranPrestige,
        img2: adaaranPrestige2,
        img3: adaaranPrestige3,
        Description:
            "Experience unrivaled luxury at Adaaran Prestige Vadoo, the world's leading water villa retreat. Nestled in the gateway to the South Atolls, this exotic resort boasts a vibrant reef teeming with aquatic wonders. Discover paradise at its finest.",
        Location: "Adaaran Prestige Vadoo, Kaafu Atoll,",
        Website: "https://www.adaaran.com/prestigevadoo",
        shortDesc: "Enjoy exclusive offer to earn free night",
        Propertyemail: "reservations@adaaran.com.mv",
        Rating: 5,
        Period: "Valid till 25th December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 6 nights and get 1 night free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
		terms18: "• This offer can be availed for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• The offer will be valid only for the Amex credit Cardholders during the aforementioned period of the offer",
        terms4: "• The discounted rate is applicable for bookings made during the offer period",
        terms5: "• Rates are inclusive of taxes (as imposed by the government)",
        terms6: "• Full payment will collect 7 days prior to arrival",
        terms7: "• Any booking unable to secure payment method will cancel after informing the guest with in 24hours",
        terms8: "• Discount is not applicable for extras consumed at the property",
        terms9: "• The discount given is based on the BAR rate as determined by the company and not on rates that may be published on other platforms",
        terms10:
            "• Cardholder must be present for the duration of the booked stay period for the offer to be valid",
        terms11:
            "• Cardholder is required to submit the Amex credit card used to make the reservation upon arrival at the Hotel for verification purposes",
        terms12:
            "• Offer will not be valid if referred to at the time of check-in or checkout",
        terms13:
            "• Please note the offer is not applicable for Corporate outings and day outings, Gift vouchers, Pre-confirmed bookings, Reservations made through any other platforms, online or offline and cannot be combined with other offers",
		terms14: "• Blackout Dates apply, from 26th December 2023 to 31st December 2023. However, last day to raise query for this offer is 20th December 2023",
        terms15: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms16: "• No minimum spending. However, must stay for 6 nights to avail of the offer ",
        terms17: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms19: "• For more information or for further assistance with your booking, contact us directly by using website link, https://www.adaaran.com/prestigevadoo",


    },
    {
        ID: 1020,
        destTitle: "Adaaran Select Hudhuranfushi",
        img: adaaranSelectHudhuran,
        img2: adaaranSelectHudhuran2,
        img3: adaaranSelectHudhuran3,
        Description:
            "Discover an unparalleled tropical paradise at Adaaran Select Hudhuran Fushi. Nestled in the North Male Atoll, this Maldivian beach resort offers breathtaking beauty with lush vegetation and pristine waters. Escape to a hidden paradise and experience a vacation beyond compare.",
        Location: "Adaaran Select Hudhuranfushi, Kaafu Atoll",
        Website: "https://www.adaaran.com/selecthudhuranfushi",
        shortDesc: "Enjoy exclusive offer to earn free night",
        Propertyemail: "reservations@adaaran.com.mv",
        Rating: 4,
        Period: "Valid till 25th December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 6 nights and get 1 night free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
		terms18: "• This offer can be availed for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• The offer will be valid only for the Amex credit Cardholders during the aforementioned period of the offer",
        terms4: "• The discounted rate is applicable for bookings made during the offer period",
        terms5: "• Rates are inclusive of taxes (as imposed by the government)",
        terms6: "• Full payment will collect 7 days prior to arrival",
        terms7: "• Any booking unable to secure payment method will cancel after informing the guest with in 24hours",
        terms8: "• Discount is not applicable for extras consumed at the property",
        terms9: "• The discount given is based on the BAR rate as determined by the company and not on rates that may be published on other platforms",
        terms10:
            "• Cardholder must be present for the duration of the booked stay period for the offer to be valid",
        terms11:
            "• Cardholder is required to submit the Amex credit card used to make the reservation upon arrival at the Hotel for verification purposes",
        terms12:
            "• Offer will not be valid if referred to at the time of check-in or checkout",
        terms13:
            "• Please note the offer is not applicable for Corporate outings and day outings, Gift vouchers, Pre-confirmed bookings, Reservations made through any other platforms, online or offline and cannot be combined with other offers",
		terms14: "• Blackout Dates apply, from 26th December 2023 to 31st December 2023. However, last day to raise query for this offer is 20th December 2023",
        terms15: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms16: "• No minimum spending. However, must stay for 6 nights to avail of the offer ",
        terms17: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms19: "• For more information or for further assistance with your booking, contact us directly by using website link, https://www.adaaran.com/selecthudhuranfushi",

    },
    {
        ID: 1021,
        destTitle: "Adaaran Select Meedhupparu",
        img: adaaranSelectMeedhupparu,
        img2: adaaranSelectMeedhupparu2,
        img3: adaaranSelectMeedhupparu3,
        Description:
            "Experience a blissful tropical getaway at Adaaran Select Meedhupparu, one of the best luxury resorts in the Maldives. Bask in healing sunshine, immerse yourself in enchanting surroundings, and indulge in tranquil luxury. Discover a paradise like no other.",
        Location: "Adaaran Select Meedhupparu, Raa Atoll",
        Website: "https://www.adaaran.com/selectmeedhupparu/",
        shortDesc: "Enjoy exclusive offer to earn free night",
        Propertyemail: "reservations@adaaran.com.mv",
        Rating: 4,
        Period: "Valid till 25th December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "no",
        Offer1: "Stay for 6 nights and get 1 night free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
		terms18: "• This offer can be availed for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• The offer will be valid only for the Amex credit Cardholders during the aforementioned period of the offer",
        terms4: "• The discounted rate is applicable for bookings made during the offer period",
        terms5: "• Rates are inclusive of taxes (as imposed by the government)",
        terms6: "• Full payment will collect 7 days prior to arrival",
        terms7: "• Any booking unable to secure payment method will cancel after informing the guest with in 24hours",
        terms8: "• Discount is not applicable for extras consumed at the property",
        terms9: "• The discount given is based on the BAR rate as determined by the company and not on rates that may be published on other platforms",
        terms10:
            "• Cardholder must be present for the duration of the booked stay period for the offer to be valid",
        terms11:
            "• Cardholder is required to submit the Amex credit card used to make the reservation upon arrival at the Hotel for verification purposes",
        terms12:
            "• Offer will not be valid if referred to at the time of check-in or checkout",
        terms13:
            "• Please note the offer is not applicable for Corporate outings and day outings, Gift vouchers, Pre-confirmed bookings, Reservations made through any other platforms, online or offline and cannot be combined with other offers",
		terms14: "• Blackout Dates apply, from 26th December 2023 to 31st December 2023. However, last day to raise query for this offer is 20th December 2023",
        terms15: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms16: "• No minimum spending. However, must stay for 6 nights to avail of the offer ",
        terms17: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms19: "• For more information or for further assistance with your booking, contact us directly by using website link, https://www.adaaran.com/selectmeedhupparu",
    },
    {
        ID: 1022,
        destTitle: "Adaaran Club Rannalhi",
        img: adaaranClubRannalhi,
        img2: adaaranClubRannalhi2,
        img3: adaaranClubRannalhi3,
        Description:
            "Experience paradise at Adaaran Club Rannalhi, one of the best hotels in the Maldives. With pristine beaches, incredible diving, and a relaxed atmosphere, it's the perfect tropical getaway. Immerse yourself in Maldivian hospitality and explore the wonders of this idyllic destination.",
        Location: "Adaaran Club Rannalhi, South Male' Atoll",
        Website: "https://www.adaaran.com/clubrannalhi/",
        shortDesc: "Enjoy exclusive offer to earn free night",
        Propertyemail: "reservations@adaaran.com.mv",
        Rating: 4,
        Period: "Valid till 25th December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "",
        Offer1: "Stay for 6 nights and get 1 night free",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
		terms18: "• This offer can be availed for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• The offer will be valid only for the Amex credit Cardholders during the aforementioned period of the offer",
        terms4: "• The discounted rate is applicable for bookings made during the offer period",
        terms5: "• Rates are inclusive of taxes (as imposed by the government)",
        terms6: "• Full payment will collect 7 days prior to arrival",
        terms7: "• Any booking unable to secure payment method will cancel after informing the guest with in 24hours",
        terms8: "• Discount is not applicable for extras consumed at the property",
        terms9: "• The discount given is based on the BAR rate as determined by the company and not on rates that may be published on other platforms",
        terms10:
            "• Cardholder must be present for the duration of the booked stay period for the offer to be valid",
        terms11:
            "• Cardholder is required to submit the Amex credit card used to make the reservation upon arrival at the Hotel for verification purposes",
        terms12:
            "• Offer will not be valid if referred to at the time of check-in or checkout",
        terms13:
            "• Please note the offer is not applicable for Corporate outings and day outings, Gift vouchers, Pre-confirmed bookings, Reservations made through any other platforms, online or offline and cannot be combined with other offers",
		terms14: "• Blackout Dates apply, from 26th December 2023 to 31st December 2023. However, last day to raise query for this offer is 20th December 2023",
        terms15: "• Cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies",
        terms16: "• No minimum spending. However, must stay for 6 nights to avail of the offer ",
        terms17: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
		terms19: "• For more information or for further assistance with your booking, contact us directly by using website link, https://www.adaaran.com/clubrannalhi",
    },
    {
        ID: 1023,
        destTitle: "Solite Restaurant",
        img: solite,
        img2: solite2,
        img3: solite3,
        Description:
            "Savor culinary perfection at Solite, where fresh ingredients and masterful preparation meet. Experience our lively, upscale atmosphere for unforgettable dining with friends, family, and business associates.",
        Location: "In front of Velaanaage, Male', Kaafu Atoll",
		Map: "https://goo.gl/maps/Ji1Gu5238XuZbvjC9",
        Website: "",
        shortDesc: "Enjoy  5% discount on your dining ",
        Propertyemail: "",
        Rating: 4,
        Period: "Valid till 31st January 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Restaurant",
        ManyOffers: "no",
        Offer1: "5% Discount on bill",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    },
    {
        ID: 1024,
        destTitle: "Khanji Maldives",
        img: kanjiMaldives,
        img2: kanjiMaldives2,
        img3: kanjiMaldives3,
        Description:
            "Experience authentic Pakistani cuisine like never before at Khanji Maldives. Located in Hulhumale', it's a culinary destination that promises an unforgettable dining experience.",
        Location: "Nirolhu Magu, Goalhi 19, Hulhumale', Kaafu Atoll",
		Map: "https://goo.gl/maps/8XxSWSskjXAtRuqr9",
        Website: "https://khanjimaldives.com/",
        shortDesc: "Enjoy 13% discount on your dining",
        Propertyemail: "",
        Rating: 4,
        Period: "Valid till 31st January 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Restaurant",
        ManyOffers: "no",
        Offer1: "13% Discount on bill",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Cannot be combined with any other offer or promotion",
        terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    },
    {
        ID: 1025,
        destTitle: "Kethi",
        img: kethi,
        img2: kethi2,
        img3: kethi3,
        Description:
            "Experience the charm of Kethi, a renovated vessel for surfing and diving in the Maldives. With cozy cabins, a stylish saloon, and spacious sun-decks, it offers a unique adventure on the water.",
        Location: "",
        Website: "https://voyagesmaldives.com/kethi/",
        shortDesc: "Enjoy exclusive offer to earn free nights",
        Propertyemail: "",
        Rating: 4,
        Period: "Valid till 31st December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Safari",
        ManyOffers: "yes",
        Offer1: "USD 1,600 per day for 6 pax",
        Offer2: "Full board – meaning 3 main meals per day and mineral water for up to 6 pax per day",
        Offer3: "Travel within the area of South & North Male'’ Atoll Only.",
        Offer4: "If over 6 pax, extra pax supplement will be charged at USD $170.00 + taxes (10% Service Charge Green Tax USD $6 + GST 12%) per night. This also includes children of all ages",
        Offer5: "Stay 10 Nights and Pay 9 Nights",
        Offer6: "Stay 14 Nights and Pay 12 Nights",
        Offer7: "Book 60 days prior to arrival, receive 20% discount on above rates (exclude Fuel Supplement charges). Book 45 days prior to arrival, receive 15% discount on above rates (exclude Fuel supplement charges). Book 15 days prior to arrival, receive 10% discount on above rates (exclude Fuel supplement charges)",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• To confirm the booking, a nonrefundable deposit of 30% of the total booking amount is required, with the remaining balance due to be paid 15 days prior to the arrival",
        terms3: "• In the unfortunate incident of a maintenance issue, an Overbooking or any other issue occurring on the boat which renders it unsuitable for the trip, the boat owner is liable to provide a similar or a better standard boat in place, although the rate might be higher than what we sell for. In the event that a replacement boat is not available, boat owner should refund in full any uncompleted segment of the trip to the Tour Operator",
        terms4: "• All alcoholic and special beverages will be charged according to bar prices and given on cash or credit card basis",
        terms5: "• If the price of oil increases by 10% or more from the current rate, this will have to be borne by the Tour Operator as fuel supplement",
        terms6: "• Separate fuel supplement for applicable atolls, other than Male' Atoll, will be charged as below",
        terms7: "Meemu Atoll - USD $400.00 per way with guest on board",
        terms8: "Faafu, Dhaalu, Thaa & Laamu - USD $700.00 per way with guest on board",
        terms9: "Atoll Huvadhoo Atoll - USD $1,000.00 per way with guest on board",
        terms10:
            "• Cost of transferring ‘Boat Only’ to the following Atolls without carrying any pax for Surf trips are:",
        terms11: "Meemu Atoll - USD $500.00 per day – 1 day each way",
        terms12:
            "Faafu, Dhaalu, Thaa & Laamu - USD $500.00 per day – 2 days each way",
        terms13:
            "Atoll Huvadhoo Atoll - USD $650.00 per day – 2.5 days each way",
        terms14:
            "• The tax mentioned are the ones currently being enforced by the Maldivian government and subject to change on government notice. The contracted partner should bear any increment to tax",
        terms15:
            "• Rates are not including health insurance for guest. Guest should take their own required travel/health insurance to cover incidental expenses relating to Covid 19 or other health issues",
        terms16:
            "• 60 - 70 days prior to the date of arrival will be charged 25% of the cost of the total stay",
        terms17:
            "• 30 - 59 days prior to the date of arrival will be charged 50% of the cost of the total stay",
        terms18:
            "• 15 - 29 days prior to the date of arrival will be charged 75% of the cost of the total stay",
        terms19:
            "• 00 - 14 days prior to the date of arrival will be charged 100% of the cost of the stay",
        terms20:
            "• ‘No Shows’ and ‘Early Departures’ will be charged 100% of the full invoice value",
        terms21:
            "• Cancellations made on the day of arrival will be considered as ‘No Shows’",
    },
    {
        ID: 1026,
        destTitle: "Adora",
        img: maldivesBoatCLub,
        img2: maldivesBoatCLub2,
        img3: maldivesBoatCLub3,
        Description:
            "Adora is a luxurious 123ft yacht with 11 spacious cabins and 4 ocean view suites on the third deck. It offers maximum tranquility at sea and is perfect for scuba diving liveaboard Maldives experience with its large living space designed for quietness and comfort.",
        Location: "",
        Website: "https://www.maldivesboatclub.com/adora/",
        shortDesc: "Enjoy exclusive offer to earn free nights",
        Propertyemail: "",
        Rating: 4,
        Period: "Valid till 31st December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Safari",
        ManyOffers: "yes",
        Offer1: "Cruise Itenerary: Best of Maldives",
        Offer2: "Pay 6 nights for 7 nights Trip",
        Offer3: "Total Number of spaces: 4",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• 35% Deposit on booking confirmation must be paid within 60 days prior to arrival",
    },
     {
        ID: 1027,
        destTitle: "Reveries Maldives",
        img: reveriesMaldives,
        img2: reveriesMaldives2,
        img3: reveriesMaldives3,
        Description:
            "Reveries Maldives in Laamu Atoll is an untouched paradise for snorkelers, divers, and lovers of wellness retreats, welcoming guests from all over the world.",
        Location: "Gan, Laamu Atoll",
        Website: "https://reveriesmaldives.com/",
        shortDesc: "Enjoy exclusive offer to earn free nights",
        Propertyemail: "reservations@reveriesmaldives.com",
        Rating: 4,
        Period: "Valid March 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "yes",
        Offer1: "Stay for 6 nights and get 1 night free",
        Offer2: "Room type: Deluxe room",
        Offer3: "Basis: Bed & Breakfast",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Above offer is valid for all markets",
        terms4: "• Arrival and departure dates should fall within the travel period",
        terms5: "• Bookings already confirmed and sold for this period are not eligible for this particular offer",
        terms6: "• Cancellation of existing bookings and re booking is not accepted",
        terms7: "• The offer is not combinable with any other offer",
        terms8: "• Availability should be checked with our reservations team prior to confirming the same with our mutual guests",
    },
    /*
    {
        ID: 1028,
        destTitle: "Arena Beach Hotel",
        img: arenaBeachHotel,
        img2: arenaBeachHotel2,
        img3: arenaBeachHotel3,
        Description:
            "Arena Beach Hotel on Maafushi Islands boasts grandiose amidst turquoise lagoons, clear sandy beaches, and sequined waters, offering flamboyant comforts for a perfect stay.",
        Location: "Maafushi, Kaafu Atoll",
        Website: "https://arenahotelsmaldives.com/",
        shortDesc: "Enjoy 5% discounts on your stay",
        Propertyemail: "reservations@arenabeachmaldives.com",
        Rating: 4,
        Period: "Valid till 31st October 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "no",
        Offer1: "Book 3 nights and get 5% discount on the 4th Night",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Offer rates applicable to room rates only",
        terms3: "• Additional terms and conditions of the property will apply",
        terms4: "• Minimum stay of 4 nights required",
    },
    {
        ID: 1029,
        destTitle: "Season Paradise",
        img: seasonParadise,
        img2: seasonParadise2,
        img3: seasonParadise3,
        Description:
            "Season Paradise is an affordable beachfront oasis on Thulusdhoo Island, featuring a restaurant, spa, and rooftop pool with infinite ocean views, providing an authentic castaway experience with modern-day comforts.",
        Location: "Thulusdhoo, Kaafu Atoll",
        Website: "https://seasonparadise.mv/",
        shortDesc: "Enjoy discount on spa treatments",
        Propertyemail: "info@seasonparadise.mv",
        Rating: 4,
        Period: "Valid till 31st October 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "no",
        Offer1: "15% discount on all spa treatments available at Season Spa",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Discount provided on all spa services from 01 March 2023 until 31 October 2023",
        terms4: "• Spa services need to be booked in-house as appointments are subject to availability",
        terms5: "• Season Spa operates from Tuesday - Sundays. There will be no appointments for Mondays",
        terms6: "• Treatment packages and products are non-refundable or exchangeable once purchased",
        terms7: "• Rescheduling and cancellations needs to be informed 4 hours prior to treatment. Cancellations within 4 hours will result in a 50% cancellation fee. No shows will be 100% chargeable",
    }, 
    {
        ID: 1030,
        destTitle: "The Mureed",
        img: theMureed,
        img2: theMureed2,
        img3: theMureed3,
        Description:
            "Mureed Hotel in Fulidhoo is just a short walk from the beach and offers great activities like snorkelling, diving, and canoeing. Plus, you can enjoy drinks at the garden bar.",
        Location: "Fulidhoo, Vaavu Atoll",
        Website: "https://the-mureed.themaldiveshotels.com/en/",
        shortDesc:
            "Enjoy exclusive offer to earn free night and earn discounts",
        Propertyemail: "info@themureed.com",
        Rating: 3,
        Period: "Valid till 1st December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "yes",
        Offer1: "Stay for 5 nights and get 1 night free on Full Board basis (excluding transfers)",
        Offer2: "Free non-motorized watercrafts (subjected to availability)",
        Offer3: "10% Discount on all room charges for bookings during the period",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• To place a booking with us the lead guest must be at least 16 years of age",
        terms4: "• The maximum number of staying guests per room must not exceed more than 3 adults in a triple occupancy",
        terms5: "• Where the person making the booking is different to the lead guest taking up the occupation, the person making the booking may be held responsible for cancellation, non-arrival and damages as set-out within",
        terms6: "• All guests agree to respect the privacy and peace of all other staying guests, neighbors and the owners at all times",
        terms7: "• We reserve the right to cancel a booking with immediate effect if guests are not honoring this agreement or causing a disturbance / nuisance to other guests, neighbors or the owners",
        terms8: "• Cancellations are made at least 5 calendar days in advance of desired check in date will incur no cancellation fee",
        terms9: "• Cancellations made between 2 and 4 calendar days in advance of desired check in date will incur a 50% cancellation fee",
        terms10:
            "• Cancellations within 1 calendar day will be charged the full amount a 100% cancellation fee",
        terms11:
            "• For any cancellations, please contact the hotel directly prior to at least 5 days prior via WhatsApp or Mail at info@themureed.com",
        terms12:
            "• For this promotional offer, payments can be made via American Express Cards. Please note that there is additional charge of 3.5% for processing credit or debit card payments levied by the bank",
        terms13:
            "• After receiving the booking a deposit of 30% of the subject of total payment will be charged. The deposit amount must be paid within 7 days to confirm the booking",
        terms14:
            "• The balance amount of total payment can be settled prior to check out by the guest after arrival",
    },
    {
        ID: 1031,
        destTitle: "Kiha Beach",
        img: kihaBeach,
        img2: kihaBeach2,
        img3: kihaBeach3,
        Description:
            "Kiha Beach in B.Dharavandhoo is a hidden gem in the Baa atoll with marine life like green turtles, Manta rays, and Whale sharks in its endless blue waters. Enjoy the annual gathering of Whale Sharks just 2 km from your hotel room. Experience the simple yet exemplary 'island life' of Dharavandhoo.",
        Location: "Dharavandhoo, Baa Atoll",
        Website: "https://kihabeach.com/",
        shortDesc:
            "Enjoy exclusive offer to earn free night and earn discounts",
        Propertyemail: "reservations@kihabeach.com",
        Rating: 4,
        Period: "Valid till 31st January 2024",
        stayPeriod: "",
        blackoutPeriod: "20th December 2023 to 10th January 2024",
        type: "Resort & Hotel",
        ManyOffers: "yes",
        Offer1: "15% Discount from listed price online",
        Offer2: "Full Board (Breakfast, Lunch & Dinner)",
        Offer3: "Complimentary 10 minutes Head & Shoulder massage",
        Offer4: "Complimentary upgrade to deluxe room",
        Offer5: "10% Discounts on F&B",
        Offer6: "10% Discounts on Watersports & Excursions",
        Offer7: "Book 6 nights and get 1 free night ",
        Offer8: "Free 1 Snorkeling trip",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Offer valid for minimum 5 nights",
    },
    {
        ID: 1032,
        destTitle: "Madivaru Kro",
        img: madivaruKro,
        img2: madivaruKro2,
        img3: madivaruKro3,
        Description:
            "Madivaru Kro in Alifu Alifu Atoll offers top-notch amenities to suit all travelers. Enjoy recreational activities such as canoeing, snorkeling, wind surfing, and yoga, among others.",
        Location: "Rasdhoo, Northern Ari Atoll",
        Website: "https://www.madivarukro.com/",
        shortDesc:
            "Enjoy 5% discount on your stay along with complimentary snorkeling",
        Propertyemail: "info@madivarukro.com",
        Rating: 3,
        Period: "Valid till 31st December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "yes",
        Offer1: "5% Discount on minimum 5 nights stay",
        Offer2: "Complimentary Snorkel Trip per stay",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card ",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Offer valid for minimum 5 nights",
    },
    {
        ID: 1033,
        destTitle: "Acqua Blu Rasdhoo",
        img: acquaBlue,
        img2: acquaBlue2,
        img3: acquaBlue3,
        Description:
            "Acqua Blu Rasdhoo is a perfect stay for both business and leisure guests in Maldives Islands. With a peaceful atmosphere, guests can enjoy various recreational facilities like canoe, wind surfing, snorkeling, fitness center and diving.",
        Location: "Rasdhoo, Northern Ari Atoll",
        Website: "https://www.acquablurasdhoo.com/",
        shortDesc:
            "Enjoy 5% discount on your stay along with complimentary snorkling",
        Propertyemail: "info@acquablurasdhoo.com",
        Rating: 3,
        Period: "Valid till 31st December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "yes",
        Offer1: "5% Discount on minimum 5 nights stay",
        Offer2: "Complimentary Snorkel Trip",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply ",
        terms3: "• Offer valid for minimum 5 nights",
    },
    {
        ID: 1034,
        destTitle: "Shallow Lagoon Rasdhoo",
        img: shallowLagoonRasdhoo,
        img2: shallowLagoonRasdhoo2,
        img3: shallowLagoonRasdhoo3,
        Description:
            "Escape to Shallow Lagoon Rasdhoo in the stunning Alif Alif Rasdhoo, Ari Atoll. With a beautiful lagoon, white sandy beach, and local shelters, it's the perfect place for a relaxing getaway.",
        Location: "Rasdhoo, Northern Ari Atoll",
        Website: "https://www.shallowlagoonrasdhoo.com/",
        shortDesc: "Enjoy complimentary sandbank trip and dinner",
        Propertyemail: "reservations@shallowlagoonrasdhoo.com",
        Rating: 3,
        Period: "Valid till 31st December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "yes",
        Offer1: "1 Free Sandbank Trip if staying for minimum of 5 nights",
        Offer2: "1 free Snorkeling trip if staying for minimum 7 nights",
        Offer3: "1 free Beach/Romantic dinner if staying for minimum of 7 nights",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• Offer is applicable for stays completed within the offer period",
        terms2: "• Stays in all room types are eligible for this offer",
        terms3: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms4: "• Additional terms and conditions of the property will apply",
    },
    {
        ID: 1035,
        destTitle: "Shallow Lagoon Sunset",
        img: shallowLagoonSunset,
        img2: shallowLagoonSunset2,
        img3: shallowLagoonSunset3,
        Description:
            "Splendid service and quality accommodation await at this 3-star beachfront hotel. With only 5 spacious and deluxe bedrooms, guests are treated to stunning sea-views and a relaxing environment to sip drinks while enjoying the ocean view. Perfect for family holidays, business trips or a relaxing getaway.",
        Location: "Rasdhoo, Northern Ari Atoll",
        Website: "https://shallowlagoonsunset.com/",
        shortDesc: "Enjoy complimentary sandbank trip and dinner",
        Propertyemail: "reservations@shallowlagoonrasdhoo.com",
        Rating: 3,
        Period: "Valid till 31st December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "yes",
        Offer1: "1 Free Sandbank Trip if staying for minimum of 5 nights",
        Offer2: "1 free Snorkeling trip if staying for minimum 7 nights",
        Offer3: "1 free Beach/Romantic dinner if staying for minimum of 7 nights",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• Offer is applicable for stays completed within the offer period",
        terms2: "• Stays in all room types are eligible for this offer",
        terms3: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms4: "• Additional terms and conditions of the property will apply",
    },
    {
        ID: 1036,
        destTitle: "Club Gabbiano",
        img: ClubGabbiano,
        img2: ClubGabbiano2,
        img3: ClubGabbiano3,
        Description:
            "Escape to a charming property just steps away from the beach on the tranquil and stunning Rasdhoo Island in the North Ari Atoll. Perfect for both couples and families, create unforgettable memories during your stay.",
        Location: "Rasdhoo, Northern Ari Atoll",
        Website: "https://www.clubgabbiano.com/",
        shortDesc: "Enjoy 5% discount with a complimentary sandbank trip",
        Propertyemail: "info@clubgabbiano.com",
        Rating: 3,
        Period: "Valid till 31st December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "yes",
        Offer1: "5% Discount on total room amount",
        Offer2: "Complimentary Sandbank trip",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card ",
        terms2: "• Additional terms and conditions of the property will apply ",
        terms3: "• Offer valid for minimum 5 nights",
    },*/
    /*{
         ID: 1037,
         destTitle: "One on Inn",
         img: oneonInn,
         img2: oneonInn2,
         img3: oneonInn3,
         Description:
             "Experience paradise at One On Inn, located in the stunning southernmost atoll of the Maldives. With breathtaking lagoon views, excellent diving, and snorkeling opportunities, it's the perfect destination for an unforgettable stay. Discover your perfect activity and immerse yourself in pure bliss.",
         Location: "Maradhoofeydhoo, Addu Atoll",
         Website: "https://www.1oninhotel.com.mv/",
         shortDesc: "Enjoy 10% discount on your stay!",
         Propertyemail: "info@1oninhotel.com.mv",
         Rating: 3,
         Period: "Valid till 31st January 2024",
         stayPeriod: "",
         blackoutPeriod: "",
         type: "Resort & Hotel",
         ManyOffers: "no",
         Offer1: "10% Discount on room charges",
         Offer2: "",
         Offer3: "",
         Offer4: "",
         Offer5: "",
         Offer6: "",
         Offer7: "",
         Offer8: "",
         Offer9: "",
         Offer10: "",
         Offer11: "",
         Offer12: "",
         Offer13: "",
         Offer14: "",
         Offer15: "",
         Offer16: "",
         terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card ",
         terms2: "• Additional terms and conditions of the property will apply ",
         terms3: "• Offer valid for room charges only",
     },
    {
        ID: 1038,
        destTitle: "Dhinasha",
        img: canopus,
        img2: canopus2,
        img3: canopus3,
        Description:
            "Enjoy a private vacation in the Maldives aboard Dhinasha with air-conditioned rooms, spacious living areas, indoor/outdoor dining, and sundeck perfect for groups.",
        Location: "",
        Website: "http://www.dhinasha.com/",
        shortDesc: "Enjoy 10% discount on us!",
        Propertyemail: "dhinasha@canopusmaldives.com",
        Rating: 4,
        Period: "Valid March 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Safari",
        ManyOffers: "no",
        Offer1: "10% Discounts on accommodation and extras",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• Offer is applicable for stays started within the offer period",
        terms2: "• Stays in all room types are eligible for this offer",
        terms3: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms4: "• Additional terms and conditions of the property will apply",
    },
    /* {
        ID: 1039,
        destTitle: "Sun Siyam Vilu Reef",
        img: sunsiyamVliuReef,
        img2: sunsiyamVliuReef2,
        img3: sunsiyamVliuReef3,
        Description:
            "Enjoy the ultimate Maldives experience in Sun Siyam Vilu Reef's water villas surrounded by stunning coral reef. Relax with spa treatments, on-site water activities, outdoor pool and pampering massages.",
        Location: "Meedhoo, South Nilandhe Atoll",
        Website: "https://www.sunsiyam.com/sun-siyam-vilu-reef/",
        shortDesc:
            "Enjoy exclusive offers including discount on your stay and many more!",
        Propertyemail: "book@sunsiyam.com",
        Rating: 5,
        Period: "Valid till 23rd December 2024",
        stayPeriod: "11th July 2023 to 23rd December 2024",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "yes",
        Offer1: "Complimentary additional 20 min massage, if booked for 60 MIN SPA Treatment",
        Offer2: "Complimentary excursions for all guests, Dolphin cruise | Local Island | Guided Snorkeling Safari",
        Offer3: "Complimentary room upgrade to next category (subject to availability up on arrival)",
        Offer4: "Bottle of Sparkling Wine or Champagne & Fruit basket upon arrival",
        Offer5: "Early Check-in and Late Check-out (Subject to availability)",
        Offer6: "Minimum 4 nights stay get additional 10% discount on the room rate",
        Offer7: "Minimum 7 nights stay get additional 15% discount on the room rate",
        Offer8: "Minimum 14 nights stay get additional 20% discount on the room rate",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Blackout Dates apply from 23rd December 2023 to 10th January 2024. However, last day to raise query for this offer is 15th December 2024",
        terms4: "• Maximum two children below 12 years of age will stay and eat for free in a villa shared with two full paying adults. (Only Green Tax and Transfers charges would be charged)",
        terms5: "• As Excursions are scheduled into combined groups, complimentary excursions are given per person, per stay",
        terms6: "• This offer cannot be combined with any other discounts, offers, or promotions, nor can it be exchanged for cash or other services",
        terms7: "• Advance booking required and can be made within the booking period",
        terms8: "• Reservation must be made prior the guest arrival and cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies ",
        terms9: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
        terms10: "• Discount is not applicable for extras consumed at the property",
		terms11: "• For more information or for further assistance with your booking, contact us directly by using website link, https://www.sunsiyam.com/sun-siyam-vilu-reef and ecommerce.iruveli@sunsiyam.com ",
    },
    {
        ID: 1040,
        destTitle: "Sun Siyam Iru Veli",
        img: sunsiyamIruveli,
        img2: sunsiyamIruveli2,
        img3: sunsiyamIruveli3,
        Description:
            "Experience the ultimate tropical retreat at Sun Siyam Iru Veli. Relax in sleek, spacious accommodations with front-row lagoon views and a freshwater pool for every five-star suite. Let the neighboring dolphins entertain you as they swim past each day. Simply check in, chill out and enjoy.",
        Location: "Sun Siyam Iru Veli, South Nilandhe Atoll",
        Website: "https://www.sunsiyam.com/sun-siyam-iru-veli/",
        shortDesc:
            "Enjoy exclusive offers including discount on your stay and many more!",
        Propertyemail: "book@sunsiyam.com",
        Rating: 5,
        Period: "Valid till 23rd December 2024",
        stayPeriod: "11th July 2023 to 23rd December 2024",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        subType: "Resort",
        ManyOffers: "yes",
        Offer1: "Free excursion trip complimentary dolphin cruise OR complimentary guided snorkeling once during the stay",
        Offer2: "Book spa prior to arrival and get 40% Discount",
        Offer3: "Kids Eat and Stay for free, depends on Parents meal plan",
        Offer4: "Minimum 4 nights stay get additional 10% discount on the room rate",
        Offer5: "Minimum 7 nights stay get additional 20% discount on the room rate",
        Offer6: "Minimum 14 nights stay get additional 30% discount on the room rate",
        Offer7: "Minimum 21 nights stay get additional 35% discount on the room rate",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card and allow for multiple stays throughout the offer period",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Blackout Dates apply from 23rd December 2023 to 10th January 2024. However, last day to raise query for this offer is 15th December 2024",
        terms4: "• Maximum two children below 12 years of age will stay and eat for free in a villa shared with two full paying adults. (Only Green Tax and Transfers charges would be charged)",
        terms5: "• As Excursions are scheduled into combined groups, complimentary excursions are given per person, per stay",
        terms6: "• This offer cannot be combined with any other discounts, offers, or promotions, nor can it be exchanged for cash or other services",
        terms7: "• Advance booking required and can be made within the booking period",
        terms8: "• Reservation must be made prior the guest arrival and cancellation of room reservations shall be subjected to the individual hotel's cancellation, amendment and no-show policies ",
        terms9: "• Offers cannot be used in conjunction with any other promotion, discounts or packages",
        terms10: "• Discount is not applicable for extras consumed at the property",
		terms11: "• For more information or for further assistance with your booking, contact us directly by using website link, https://www.sunsiyam.com/sun-siyam-vilu-reef and ecommerce.iruveli@sunsiyam.com ",
    }, */
	/*{
        ID: 1041,
        destTitle: "Lime Restaurant",
        img: lime,
        img2: lime2,
        img3: lime3,
        Description:
            "Savour Southeast Asian flavours at our all-day restaurant Lime. Start your day off on the right foot with our breakfast spread. Discover new cuisines and revisit old favourites with our modern Asian buffet dinners",
        Location: "JEN Maldives, Male', Kaafu Atoll",
		Map: "https://goo.gl/maps/Ps2bEjSFTxCE9oHY6",
        Website: "",
        shortDesc: "Enjoy 15% Discount on your dining",
        Propertyemail: "",
        Rating: 4,
        Period: "Valid till 29th February 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Restaurant",
        ManyOffers: "no",
        Offer1: "15% Discount on bill",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    },
	{
        ID: 1042,
        destTitle: "Azur Restaurant",
        img: azure,
        img2: azure2,
        img3: azure3,
        Description:
            "Discover the joys of island life at a stunning rooftop restaurant. Enjoy sushi, fresh Maldivian tuna, and grilled meats as you take in the expansive views of the Indian Ocean",
        Location: "JEN Maldives, Male', Kaafu Atoll",
		Map: "https://goo.gl/maps/Ps2bEjSFTxCE9oHY6",
        Website: "",
        shortDesc: "Enjoy 15% discount on your dining",
        Propertyemail: "",
        Rating: 4,
        Period: "Valid till 29th February 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Restaurant",
        ManyOffers: "no",
        Offer1: "15% Discount on bill",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    },
	{
        ID: 1043,
        destTitle: "Jen's Kitchen On-the-Go",
        img: jenskitchen,
        img2: jenskitchen2,
        img3: jenskitchen3,
        Description:
            "Refuel with coffee and snacks at Jen's Kitchen On-the-Go",
        Location: "JEN Maldives, Male', Kaafu Atoll",
		Map: "https://goo.gl/maps/Ps2bEjSFTxCE9oHY6",
        Website: "",
        shortDesc: "Enjoy 15% discount on your dining",
        Propertyemail: "",
        Rating: 4,
        Period: "Valid till 29th February 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Restaurant",
        ManyOffers: "no",
        Offer1: "15% Discount on bill",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    },*/
	/*{
        ID: 1044,
        destTitle: "K Cafe'",
        img: kcafe,
        img2: kcafe2,
        img3: kcafe3,
        Description:
            "Discover an extensive range of Asian fusion cuisine including Sri Lankan meals, open for both residents and non-residents.",
        Location: "Mookai Suites, Male', Kaafu Atoll",
		Map: "https://goo.gl/maps/XEYMoieysF5ePWtj6",
        Website: "",
        shortDesc: "Enjoy 10% discount on your dining and takeaway",
        Propertyemail: "",
        Rating: 4,
        Period: "Valid till 17th February 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Restaurant",
        ManyOffers: "no",
        Offer1: "10% Discount on bill",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    },
	{
        ID: 1045,
        destTitle: "High Tide Restaurant",
        img: hightide,
        img2: hightide2,
        img3: hightide3,
        Description:
            "Designed to maximise those endless sea views, our signature restaurant High Tide is perfect for open-air dining, where guests can sit under the stars, and connect with the landscape.",
        Location: "Seasalter Maldives, Hulhumale', Kaafu Atoll",
		Map: "https://goo.gl/maps/WojSHvUC56XxK3dE7",
        Website: "",
        shortDesc: "Enjoy 10% discount on your dining and deliveries",
        Propertyemail: "",
        Rating: 4,
        Period: "Valid till 31st December 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Restaurant",
        ManyOffers: "no",
        Offer1: "10% Discount on bill",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    },
    */
	// {
    //     ID: 1046,
    //     destTitle: "Nihonbashi Blue",
    //     img: nihonbashi,
    //     img2: nihonbashi2,
    //     img3: nihonbashi3,
    //     Description:
    //         "Casual by the sea Japanese restaurant by Sri Lanka's Nihonbashi Restaurant. Emulating its Colombo branch, with its sake bar, sushi counter with dedicated sushi chefs, Nihonbashi Blue also houses Maldives’s first Tatami seating area for families and larger gatherings to enjoy a more true Japanese experience.",
    //     Location: "The Marina at the Crossroads, Kaafu Atoll",
	// 	Map: "https://goo.gl/maps/1ifPuoptKa7poDt98",
    //     Website: "",
    //     shortDesc: "Enjoy 20% discount on your dining",
    //     Propertyemail: "",
    //     Rating: 4,
    //     Period: "Valid till 31st July 2023",
    //     stayPeriod: "",
    //     blackoutPeriod: "",
    //     type: "Restaurant",
    //     ManyOffers: "no",
    //     Offer1: "20% Discount on bill",
    //     Offer2: "",
    //     Offer3: "",
    //     Offer4: "",
    //     Offer5: "",
    //     Offer6: "",
    //     Offer7: "",
    //     Offer8: "",
    //     Offer9: "",
    //     Offer10: "",
    //     Offer11: "",
    //     Offer12: "",
    //     Offer13: "",
    //     Offer14: "",
    //     Offer15: "",
    //     Offer16: "",
    //     terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
    //     terms2: "• Additional terms and conditions of the property will apply",
    //     terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    // },
	// {
    //     ID: 1047,
    //     destTitle: "Ministry of Crab",
    //     img: ministryofcrab,
    //     img2: ministryofcrab2,
    //     img3: ministryofcrab3,
    //     Description:
    //         "Housed in a colonial style building on the waterfront, at The Marina @ CROSSROADS, Ministry of Crab Maldives faces the beautiful marina, providing our guests with spectacular views, breathtaking sunsets and the balmy coastal breeze",
    //     Location: "The Marina at the Crossroads, Kaafu Atoll",
	// 	Map: "https://goo.gl/maps/WcdeG1h7cPVJsqBz6",
    //     Website: "",
    //     shortDesc: "Enjoy 20% discount on your dining",
    //     Propertyemail: "",
    //     Rating: 4,
    //     Period: "Valid till 31st July 2023",
    //     stayPeriod: "",
    //     blackoutPeriod: "",
    //     type: "Restaurant",
    //     ManyOffers: "no",
    //     Offer1: "20% Discount on bill",
    //     Offer2: "",
    //     Offer3: "",
    //     Offer4: "",
    //     Offer5: "",
    //     Offer6: "",
    //     Offer7: "",
    //     Offer8: "",
    //     Offer9: "",
    //     Offer10: "",
    //     Offer11: "",
    //     Offer12: "",
    //     Offer13: "",
    //     Offer14: "",
    //     Offer15: "",
    //     Offer16: "",
    //     terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
    //     terms2: "• Additional terms and conditions of the property will apply",
    //     terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    // },
	// {
    //     ID: 1048,
    //     destTitle: "Carne Diem",
    //     img: carnediem,
    //     img2: carnediem2,
    //     img3: carnediem3,
    //     Description:
    //         "At Carne Diem, we are inspired by the first human discovery, fire and have created our own Charcoal Grill-Oven. This original grill-oven was designed to harness the raw power of charcoal and its energy to create a new cooking temperature, 750 Degree Celsius.",
    //     Location: "The Marina at the Crossroads, Kaafu Atoll",
	// 	Map: "https://goo.gl/maps/KDEZLuNuFvw41qT7A",
    //     //Website: "",
    //     shortDesc: "Enjoy 20% discount on your dining",
    //     Propertyemail: "",
    //     Rating: 4,
    //     Period: "Valid till 31st July 2023",
    //     stayPeriod: "",
    //     blackoutPeriod: "",
    //     type: "Restaurant",
    //     ManyOffers: "no",
    //     Offer1: "20% Discount on bill",
    //     Offer2: "",
    //     Offer3: "",
    //     Offer4: "",
    //     Offer5: "",
    //     Offer6: "",
    //     Offer7: "",
    //     Offer8: "",
    //     Offer9: "",
    //     Offer10: "",
    //     Offer11: "",
    //     Offer12: "",
    //     Offer13: "",
    //     Offer14: "",
    //     Offer15: "",
    //     Offer16: "",
    //     terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
    //     terms2: "• Additional terms and conditions of the property will apply",
    //     terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    // },
    /*
    {
        ID: 1049,
        destTitle: "Ooredoo Maldives",
        img: ooredoo,
        img2: ooredoo2,
        img3: ooredoo3,
        Description: "To avail this offer, please visit our counter at the Velana International Airport and pay with your American Express card.",
        Location: "Located in the Concourse area outside the International Arrivals gate, Velana International Airport",
		Website: "https://www.ooredoo.mv/",
        shortDesc: "Enjoy exclusive benefits with our Tourist SIM package",
        Propertyemail: "care@ooredoo.mv",
        Rating: 0,
        Period: "Valid till 30th April 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Others",
        subType: "",
        ManyOffers: "yes",
        Offer1: "Free 5GB data on the second recharge on any tourist plans via American Express cards",
        Offer2: "Free Crossroads trip to all tourist SIMs purchased from American Express cards",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Tourist SIM pouch must be presented at Crossroads counter to be eligible for free Crossroads trip",
        terms3: "• American Express card to be presented at the Airport Shop or Seaplane Terminal",
    },
    {
        ID: 1050,
        destTitle: "Dhiraagu",
        img: dhiraagu,
        img2: dhiraagu2,
        img3: dhiraagu3,
        Description: "To avail this offer, please visit our counter at the Velana International Airport and pay with your American Express card.",
        Location: "Located in the Concourse area outside the International Arrivals gate, Velana International Airport",
		Website: "https://www.dhiraagu.com.mv/",
        shortDesc: "Enjoy extra Data with our Tourist SIM package",
        Propertyemail: "123@dhiraagu.com.mv",
        Rating: 0,
        Period: "Valid till 30th April 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Others",
        subType: "",
        ManyOffers: "yes",
        Offer1: 'Get 10GB Social Media Allowance by purchasing our "Traveler Lite" Tourist SIM package',
        Offer2: " ",
        Offer3: "Price - US$ 40",
        Offer4: "Credit - MVR 100",
        Offer5: "Normal Allowance - 20GB",
        Offer6: "Local Minutes - 150 Mins",
        Offer7: "Local SMS - 150 SMS",
        Offer8: "Validity - 30 Days",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• American Express card to be presented at the Airport Shop or Seaplane Terminal",
        terms3: "• Allowance will be activated within 48 hours of purchase",
    },
    {
        ID: 1051,
        destTitle: "Vilu Business Lounge - Velana International Airport",
        img: macl,
        img2: macl2,
        img3: macl3,
        Description: "To avail this offer, please visit our terminal at the Velana International Airport and pay with your American Express card.",
		Location: "Located inside the Noovilu Seaplane Terminal, Velana International Airport",
        Website: "https://macl.aero/",
        shortDesc: "Enjoy over 50% discount on your visits to the Lounge",
        Propertyemail: "",
        Rating: 0,
        Period: "Valid till 30th April 2024",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Others",
        subType: "",
        ManyOffers: "yes",
        Offer1: "Pay with American Express cards and get over 50% discount at Vilu Business Lounge",
        Offer2: "",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Offer is applicable to walk-in passengers to Vilu Business Lounge located at Noovilu Seaplane Terminal of Velana International Airport",
    },
	/*{
        ID: 1052,
        destTitle: "Belle Amie Bistro",
        img: belleamie,
        img2: belleamie2,
        img3: belleamie3,
        Description:
            "Offering the finest local and international cuisines, our chefs are artists in their craft and will make your taste buds dance to tunes ranging from salsa to katak and everything in between.",
        Location: "The Somerset Hotel, Male', Kaafu Atoll",
		Map: "https://goo.gl/maps/h3rCC5eTN9FjfBEU9",
        //Website: "",
        shortDesc: "Enjoy 10% discount on your dining and other events",
        Propertyemail: "",
        Rating: 4,
        Period: "Valid till 31st August 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Restaurant",
        ManyOffers: "no",
        Offer1: "10% discount on all A la carte meals from the main menu",
        Offer2: "10% discount on birthday party packages",
        Offer3: "10% discount on the weekend dinner buffet",
        Offer4: "10% discount on afternoon tea",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Additional terms and conditions of the property will apply",
        terms3: "• Please visit the restaurant and enjoy your food to avail this offer.",
    }*/
	/* {
		ID: 1053,
        destTitle: "Tourist Inn",
        img: touristinn,
        img2: touristinn2,
        img3: touristinn3,
        Description:
            "Nestled in the heart of Male', Tourist Inn offers a perfect blend of luxurious accommodations and convenient access to key locations",
        Location: "Male', Kaafu Atoll",
        Website: "https://www.touristinnmaldives.com/",
        shortDesc: "Enjoy Exclusive offers to your American Express Card",
        Propertyemail: "info@touristinnmaldives.com",
        Rating: 4,
        Period: "Valid till 30th September 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "Yes",
        Offer1: "10% off for American Express",
        Offer2: "Stay for 7 Nights and get 1 night Fee",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Cannot be combined with any other offer or promotion",
	},
	{
		ID: 1054,
        destTitle: "Tourist Inn Grand",
        img: touristinngrand,
        img2: touristinngrand2,
        img3: touristinngrand3,
        Description:
            "Experience the perfect blend of luxury, convenience, and authentic Maldivian charm at our hotel in Male",
        Location: "Male', Kaafu Atoll",
        Website: "https://www.touristinnmaldives.com/",
        shortDesc: "Enjoy Exclusive offers to your American Express Card",
        Propertyemail: "info@touristinnmaldives.com",
        Rating: 4,
        Period: "Valid till 30th September 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "Yes",
        Offer1: "10% off for American Express",
        Offer2: "Stay for 7 Nights and get 1 night Fee",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Cannot be combined with any other offer or promotion",
	},
	{
		ID: 1055,
        destTitle: "Tour Inn",
        img: tourinn,
        img2: tourinn2,
        //img3: 
        Description:
            "Located in the vibrant heart of Male', we offer easy access to the city's key locations",
        Location: "Male', Kaafu Atoll",
        Website: "https://tourinnmaldives.com/",
        shortDesc: "Enjoy Exclusive offers to your American Express Card",
        Propertyemail: "info@touristinnmaldives.com",
        Rating: 4,
        Period: "Valid till 30th September 2023",
        stayPeriod: "",
        blackoutPeriod: "",
        type: "Resort & Hotel",
        ManyOffers: "Yes",
        Offer1: "10% off for American Express",
        Offer2: "Stay for 7 Nights and get 1 night Fee",
        Offer3: "",
        Offer4: "",
        Offer5: "",
        Offer6: "",
        Offer7: "",
        Offer8: "",
        Offer9: "",
        Offer10: "",
        Offer11: "",
        Offer12: "",
        Offer13: "",
        Offer14: "",
        Offer15: "",
        Offer16: "",
        terms1: "• All offers are valid to American Express card members only and Payment must be made with an American Express card",
        terms2: "• Cannot be combined with any other offer or promotion",
	} */ 
];

export default Data;
