import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Aboutus.css";

import 'react-awesome-slider/dist/styles.css';

//my components
import Navbar from "../Navbarscreen/Navbar";
import Footer from "../Footerscreen/Footer";

import Aos from "aos";
import "aos/dist/aos.css";

const Aboutus = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();


    const gotoHome = () => {
        navigate("/");
    };

    return (
        <>
            <Navbar />
            <section data-aos="fade-up" className="detail section container">
                <div className="detailedView">
                    <button onClick={gotoHome} className="btn detailbtn">
                        Home
                    </button>
                    <div className="aboutM">
                        <h1>About Maldives</h1>
                        <p className="paragraph">The Maldives, an island nation in the Indian Ocean, is known for its unique geography and captivating beauty. Comprising 1,192 islands spanning 871 kilometers, 
                            the country stretches across the equator and is grouped into 26 atolls. With only 298 square kilometers of dry land out of its total area of around 90,000 square kilometers,
                             the Maldives offers a mesmerizing landscape of tiny jewel-like islands rimmed with white sandy beaches and surrounded by clear shallow waters. </p>

                        <p className="paragraph">Below the water's surface, the Maldives boasts approximately five percent of the world's reefs, adorned with vibrant soft and hard corals that create an explosion of color.
                             These reefs provide a habitat for a thousand species of fish, as well as attract large pelagic creatures like manta rays and whale sharks. Only 200 of the islands are inhabited,
                              while some are dedicated to resorts, industry, and agriculture. </p>

                        <p className="paragraph">The Maldivian people have a rich cultural heritage shaped by centuries of interaction among different races and ethnicities that crossed the Indian Ocean. The population of over 
                            half a million is concentrated in the capital city of Male' and scattered across 200 islands. Traditionally, livelihoods depended on fishing, but tourism has become the main source 
                            of income for the economy. </p>

                        <p className="paragraph">The delicate environment of the Maldives revolves around its coral reefs, which serve as a natural defense system for the islands. Conservation efforts are underway to protect the 
                            marine environment, including designated nature reserves and biosphere reserves that encompass coral reefs, mangroves, and wetlands. Resorts and non-governmental organizations also
                             play a role in conservation through programs focused on sea turtle rehabilitation, coral reef regeneration, and community education. </p>

                        <p className="paragraph"> Connectivity to the Maldives is facilitated by international flights to Velana International Airport from Europe, the Middle East, and Southeast Asia. Domestic and international 
                            airports on various islands, along with ferry services, provide transportation within the country. Resort transfers are arranged by speedboat or seaplane. </p>

                        <p className="paragraph"> In summary, the Maldives is a captivating island nation with a delicate environment and rich cultural heritage. Its picturesque geography, underwater beauty, and conservation 
                            efforts make it a popular destination for visitors from around the world. </p>

                    </div>
                    <div className="Contacts">
                    <h2>Important Contacts</h2>
                    <ol>
                        <li style={{ marginTop: '8px' }}>•	MALDIVES POLICE SERVICE -119 </li>
                        <li style={{ marginTop: '8px' }}>•	FIRE& RESCUE -118</li>
                        <li style={{ marginTop: '8px' }}>•	AMBULANCE -112</li>
                        <li style={{ marginTop: '8px' }}>•	COAST GUARD -191</li>
                        <li style={{ marginTop: '8px' }}>•	INDIRA GANDHI MEMORIAL HOSPITAL -3335335</li>
                        <li style={{ marginTop: '8px' }}>•	ADK HOSPITAL -3313553</li>
                        <li style={{ marginTop: '8px' }}>•	TREE TOP HOSPITAL -3351610</li>
                        <li style={{ marginTop: '8px' }}>•	FLIGHT INFORMATION -3322211</li>
                        <li style={{ marginTop: '8px' }}>•	BANK OF MALDIVES -3330200</li>
                    </ol>

                    </div>

                </div>
            </section>
            <Footer />
        </>
    );
};

export default Aboutus;
