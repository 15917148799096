import React, { useEffect, useRef } from "react";
import "./home.css";

// my components
import Navbar from "../Navbarscreen/Navbar";
import Main from "../Mainscreen/Main";
import Footer from "../Footerscreen/Footer";
import Slider from "./Slider";

import image from "../../Assets/image5.jpg";

import Aos from "aos";
import "aos/dist/aos.css";

const Home = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    const offersRef = useRef(null);

    const scrollToOffers = () => {
        offersRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    return (
        <>
            <Navbar scrollTo={scrollToOffers} />
            <div className="home">
                <Slider slidercss={"slider"} />
                {/* <img className="slider" src={image} alt="image1" /> */}
            </div>

            <div ref={offersRef}>
                <Main />
            </div>

            <Footer />
        </>
    );
};

export default Home;
