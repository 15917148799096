import React, { useState,useRef, useEffect } from 'react';
import './navbar.css';
import axios from 'axios';
import BmlLogo from './Logo/BML';
import AmexLogo from './Logo/Amex';
import { useNavigate } from "react-router-dom";
import { WiDaySunny, WiMoonAltFull, WiDayCloudyHigh, WiNightCloudy, WiNightAltCloudyHigh, WiDayCloudy, WiDayRain, WiNightRain, WiDayShowers, WiNightAltShowers } from  "weather-icons-react";
const Navbar = () => {
  const [temperature, setTemperature] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //const [condition, setCondition] = useState(null);
  const [icon, setConditionicon] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const modalContentRef = useRef(); // Add this line
  const navigate = useNavigate();
    const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {setShowMobileMenu(!showMobileMenu);};

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const modalBackgroundClicked = (e) => {
    if (modalContentRef.current && !modalContentRef.current.contains(e.target)) {
      closeModal();
    }
  };

  const abtmal = () => {
    navigate("/Aboutus");
};

  const iconMapping = {
    '01d': WiDaySunny,
    '01n': WiMoonAltFull,
    '02d': WiDayCloudy,
    '02n': WiNightCloudy,
    '03d': WiDayCloudyHigh,
    '03n': WiNightAltCloudyHigh,
    '04d': WiDayCloudyHigh,
    '04n': WiNightAltCloudyHigh,
    '09d': WiDayShowers,
    '09n': WiNightAltShowers,
    '10d': WiDayRain,
    '10n': WiNightRain,
    // ... add more mappings for other weather conditions
  };

  useEffect(() => {
    // Provided city and API key are used here.
    axios.get('http://api.openweathermap.org/data/2.5/weather?q=Male,MV&units=metric&appid=3082d7d69afe9410d6108da38f151ce6')
      .then(response => {
        setTemperature(response.data.main.temp);
        //setCondition(response.data.weather[0].description);
        setConditionicon(response.data.weather[0].icon);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);


  return (
    <section className='navBarSection'>
      <header className='header'>
        <div className='LogoDiv'>
          <a href='/' className='LogoDiv'>
            <div className='left'>
              <BmlLogo />
            </div>
            <div>
              <AmexLogo />
            </div>
          </a>
        </div>
      <div className={`rightside ${showMobileMenu ? 'mobile-menu' : ''}`}>

        <button className='AboutButton' onClick={abtmal}>
          About Maldives
        </button>
        

        <button className='offerButton' onClick={openModal}>
          How to get offers
        </button>
        </div>

        {showModal && (
          <div className='modal'onClick={modalBackgroundClicked}>
            <div className='modal-content' ref={modalContentRef}>
              <h2>How to Get Offers</h2>
              <ol>
                <li style={{ marginTop: '8px' }}>1) Select your offer from Home page and view offer details by clicking “Learn more”.</li>
                <li style={{ marginTop: '8px' }}>2) To avail the offer, click “Get offer”</li>
                <li style={{ marginTop: '8px' }}>3) Fill in the required details and click “Submit”.</li>
                <li style={{ marginTop: '8px' }}>4) You will receive a confirmation email from us confirming the receipt of your request</li>
                <li style={{ marginTop: '8px' }}>5) The property will reach out to you on the provided email or mobile number with booking details and a payment link</li>
                <li style={{ marginTop: '8px' }}>6) You can proceed to make payment using your American Express card.</li>
                <li style={{ marginTop: '8px' }}>7) For Restaurant offers, simply visit the restaurant and enjoy your food to avail the offers.</li>
                <li style={{ marginTop: '8px' }}>8) For other offers (Telecommunication and Airport Lounges), please view offer details for instructions on how to avail the offers.</li>
              </ol>
              <button onClick={closeModal}>Close</button>
            </div>
          </div>
        )}

        
      </header>
    </section>
  );
};

export default Navbar;
