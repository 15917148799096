import React, { useEffect } from "react";
import "./landing.css";
import Footer from "../Footerscreen/Footer";
import Navbar from "../Navbarscreen/Navbar";
import { useLocation, useNavigate } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";

const Landing = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 });
    }, []);

    const navigate = useNavigate();
    const location = useLocation();

    const Return = () => {
        navigate("/");
    };
    const { data } = location.state;
    return (
        <div className="bgLandingcolor">
            <Navbar />
            <div className="landingMain">
                <div className="textSection">
                    <div className="MainText top">Experience Maldives
                    <div className="Submission">
                        Thank you for your submission!
                    </div>
                    </div>

                    <div className="middleText">
                    {data.destTitle} will be reaching out to you via
                            e-mail within 48 hours to complete the booking
                            process. A copy of your request will be sent to your
                            e-mail.
                    </div>

                    <div className="bottomText">
                          If you do not receive an email within 48 hours, please contact us directly at experiencemaldives@bml.com.mv
                    </div>

                    <button onClick={Return} className="btn landingBtn">
                        Okay
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Landing;
