import React, { useEffect, useState } from "react";
import "./main.css";
import { useNavigate } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";

import Data from "./Data";

const Main = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, []);

    //filter cards or offers and change buttons css
    const [FilteredData, setFilteredData] = useState(Data);
    const [active, setActive] = useState("");

    const handleFilter = (filterCriteria) => {
        setFilteredData(Data.filter((item) => item.type === filterCriteria));
        setActive(filterCriteria);
    };

    const navigate = useNavigate();

    const sendCardData = (parentObject) => {
        navigate("/details", { state: { data: parentObject } });
    };

    return (
        <section
            id="main"
            data-aos="fade-up"
            className="main section container"
        >
            <div className="textSection">
                <div className="MainText">Experience Maldives</div>
                <div className="subText mobilehide">
                    Enjoy exclusive offers from resorts, safaris, hotels &
                    restaurants when you use your American Express card
                </div>
                <div className="subText pcHide">
                    Enjoy exclusive offers from resorts, safaris, hotels &
                    restaurants
                </div>
                <div className="subText sub2 pcHide">
                    when you use your American Express card
                </div>
            </div>

            <div className="packages">
                <button
                    onClick={() => handleFilter("Resort & Hotel")}
                    className={
                        active === "Resort & Hotel"
                            ? "active"
                            : "packageButtons"
                    }
                >
                    {" "}
                    Resort & Hotel
                </button>

                <button
                    onClick={() => handleFilter("Safari")}
                    className={
                        active === "Safari" ? "active" : "packageButtons"
                    }
                >
                    {" "}
                    Safari
                </button>

                {/* <button
                    onClick={() => handleFilter("Guest House")}
                    className={
                        active === "Guest House" ? "active" : "packageButtons"
                    }
                >
                    {" "}
                    Guest House
                </button> */}
                <button
                    onClick={() => handleFilter("Restaurant")}
                    className={
                        active === "Restaurant" ? "active" : "packageButtons"
                    }
                >
                    {" "}
                    Restaurant
                </button>
                <button
                    onClick={() => handleFilter("Others")}
                    className={
                        active === "Others" ? "active" : "packageButtons"
                    }
                >
                    {" "}
                    Others
                </button>
            </div>

            <div className="secContent grid">
                {FilteredData.map((data) => {
                    return (
                        <div
                            key={data.id}
                            data-aos="fade-up"
                            className="singleDestination"
                        >
                            <div className="imageDiv">
                                <img src={data.img} alt="" />
                            </div>

                            <div className="cardInfo">
                                <h4 className="destTitle">{data.destTitle}</h4>

                                {data.Rating === 0 ? (
                                    <div className="noRating"></div>
                                ) : (
                                    <div className="fees flex">
                                        <div>
                                            <div className="Rating">
                                                {"★".repeat(data.Rating)}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="smalldesc">
                                    {data.shortDesc}
                                </div>

                                <div className="desc">
                                    <p>{data.Period}</p>
                                </div>

                                <button
                                    onClick={() => sendCardData(data)}
                                    className="btnmain flex"
                                >
                                    Learn more
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default Main;
