import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/autoplay";

import image from "../../Assets/Adventure.jpg";
import image2 from "../../Assets/Escape.jpg";
import image3 from "../../Assets/Happiness.jpg";
import image4 from "../../Assets/Reconnect.jpg";
import image5 from "../../Assets/Sunsets.jpg";

const Slider = ({ slidercss }) => {
    return (
        <Swiper
            modules={[Autoplay]}
            autoplay={{ delay: 4000 }}
            spaceBetween={32}
            speed={700}
            className={slidercss}
        >
            <SwiperSlide>
                <img className="slider" src={image} alt="image1" />
            </SwiperSlide>
            <SwiperSlide>
                <img className="slider" src={image2} alt="image2" />
            </SwiperSlide>
            <SwiperSlide>
                <img className="slider" src={image3} alt="image3" />
            </SwiperSlide>
            <SwiperSlide>
                <img className="slider" src={image4} alt="image4" />
            </SwiperSlide>
            <SwiperSlide>
                <img className="slider" src={image5} alt="image5" />
            </SwiperSlide>
        </Swiper>
    );
};

export default Slider;
