import React from "react";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// my components
import "./app.css";
import Home from "./Components/Homescreen/Home";
import Form from "./Components/Formscreen/Form";
import Detail from "./Components/Detailscreen/Detail";
import Landing from "./Components/Landingscreen/Landing";
import Aboutus from "./Components/Aboutscreen/Aboutus";

const App = () => {
    return (
        <AnimatePresence initial={false} mode="wait">
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/details" element={<Detail />} />
                    <Route path="/Aboutus" element={<Aboutus />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/landing" element={<Landing />} />
                </Routes>
            </Router>
        </AnimatePresence>
    );
};

export default App;
