import React, { useEffect, useState,useRef } from "react";

import { useForm } from "react-hook-form";
import { useLocation, useNavigate, Link } from "react-router-dom";
import emailjs from "@emailjs/browser";


import "./form.css";
import BML from "../Navbarscreen/Logo/BML";
import Amex from "../Navbarscreen/Logo/Amex";

import Aos from "aos";
import "aos/dist/aos.css";



export default function Form() {

    const [showModal, setShowModal] = useState(false);
    const modalContentRef = useRef(); // Add this line
    
    const openModal = (e) => {
    e.preventDefault();
      setShowModal(true);
    };
    
    const closeModal = () => {
      setShowModal(false);
    };
    
    const modalBackgroundClicked = (e) => {
      if (modalContentRef.current && !modalContentRef.current.contains(e.target)) {
        closeModal();
      }
    };

    useEffect(() => {
        Aos.init({ duration: 3000 });
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const location = useLocation();
    const { data } = location.state;

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const [populate, setPopulate] = useState({});

    const sendData = (parentObject) => {
        setPopulate(parentObject);
    };


    

    const onSubmit = (data) => {
        emailjs.send(
            "service_id5lp8l",
            "template_hpzzr28",
            {
                destination: data.destination,
                firstName: data.firstName,
                lastName: data.firstName,
                NOadult: data.numberOfadult,
                NOchildren: data.numberOfchildren,
                dateofcheckin: data.checkin,
                dateofcheckout: data.checkout,
                nationality: data.nationality,
                email: data.email,
                number: data.number,
                destMail: data.destMail,
            },
            "Xu7TQw4nl_DzqfSHB"
        );
        emailjs.send(
            "service_id5lp8l",
            "template_0zc3j9o",
            {
                firstName: data.firstName,
                email: data.email,
                destination: data.destination,
                dateofcheckin: data.checkin,
                dateofcheckout: data.checkout,
                destMail: data.destMail,
            },
            "Xu7TQw4nl_DzqfSHB"
        );
        navigate("/landing", { state: { data: populate } });
    };

    return (
        <section className="App bgcolor">
            <div data-aos="fade-down" className="register">
                <div className="col-1">
                    {" "}
                    <div className="LogoDiv">
                        <div className="left">
                            <BML />
                        </div>
                        <div>
                            <Amex />
                        </div>
                    </div>
                    <div className="Branding">Experience Maldives</div>
                    <h2 className="merchantTitle">{data.destTitle}</h2>
                    <form
                        id="form"
                        className="flex flex-col"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <input
                            type="text"
                            {...register("destMail")}
                            defaultValue={data.Propertyemail}
                            className="formDestTitle"
                        />
                        <input
                            type="text"
                            {...register("destination")}
                            defaultValue={data.destTitle}
                            className="formDestTitle"
                        />

                        <div className="formDetails">
                            {" "}
                            <label className="label">Enter Your Details</label>
                            <div className="firstsection">
                                <input
                                    type="text"
                                    {...register("firstName")}
                                    placeholder="First Name"
                                    className="placeholder"
                                    required
                                />
                                <input
                                    type="text"
                                    {...register("lastName")}
                                    placeholder="Last Name"
                                    className="placeholder"
                                    required
                                />
                            </div>
                            <div className="firstsection">
                                <input
                                    type="email"
                                    {...register("email")}
                                    placeholder="Email"
                                    className="placeholder"
                                    required
                                />
                                <input
                                    type="number"
                                    min="0"
                                    {...register("number")}
                                    placeholder="Contact Number"
                                    className="placeholder"
                                    required
                                />
                            </div>
                            <input
                                type="text"
                                {...register("nationality")}
                                placeholder="Nationality"
                                className="placeholder"
                                required
                            />
                            <div className="firstsection">
                                <input
                                    type="number"
                                    min="0"
                                    {...register("numberOfadult")}
                                    placeholder="No. of adults"
                                    className="placeholder"
                                    required
                                />
                                <input
                                    type="number"
                                    min="0"
                                    {...register("numberOfchildren")}
                                    placeholder="No. of children"
                                    className="placeholder"
                                />
                            </div>
                            <div className="part2div">
                                <div className="secondsection">
                                    <label className="label">
                                        Select Travel Dates
                                    </label>
                                    <div className="date">
                                        <label className="datelabel">
                                            Check In
                                        </label>
                                        <input
                                            type="date"
                                            {...register("checkin")}
                                            placeholder="check in"
                                            required
                                            className="placeholder"
                                        />
                                    </div>

                                    <div className="date2">
                                        <label className="datelabel">
                                            Check Out
                                        </label>

                                        <input
                                            type="date"
                                            {...register("checkout")}
                                            placeholder="check out"
                                            className="placeholder"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {errors.firstName?.type === "required" && "fill it"}
                        <div className="buttons-container">
                            <button
                                onClick={() => sendData(data)}
                                className="btn formbtn"
                            >
                                Submit
                            </button>
                            <button
                                onClick={handleBack}
                                className="btn formbtn formbtn-back" 
                            >
                                Back
                            </button>
                        </div>
                            
                        <div className="terms">
                            By submitting this form, you agree to our &nbsp;
                            <a href='#' onClick={openModal} className="link">
                                Terms & Conditions
                                </a> and &nbsp;   
                            <a href="https://www.bankofmaldives.com.mv/privacy-statement" target="_blank" rel="noopener noreferrer">
                             Data Protection Policies
                            </a>
                        </div>
                    </form>
                </div>
            </div>

            {showModal && (
             <div className='modal'onClick={modalBackgroundClicked}>

                <div className='modal-content' ref={modalContentRef}>
                
                <h2>Terms & Conditions</h2>
                
                    <p>
                    All offers will be subject to Terms and Conditions.
                    <ol style={{ marginTop: '10px' }}>
                        <li style={{ marginTop: '8px' }}>
                        • These offers are open to all American Express Cardmembers
                        whose accounts are valid and in good standing. An American
                        Express Cardmember ("Cardmember") for the purpose of these
                        offers means a person holding a Card, issued either by
                        American Express® or a third party bearing the name or
                        trademark or service mark or logo of American Express (“Card”).
                        </li>
                        <li style={{ marginTop: '8px' }}>
                        • These offers are being made purely on a “best effort”
                        basis. Cardmembers are not bound in any manner to participate
                        in these offers and any such participation is purely voluntary
                        </li>
                        <li style={{ marginTop: '8px' }}>
                        • American Express is neither responsible for availability of
                        services nor guarantees the quality of the goods/services and
                        is not liable for any defect or deficiency of goods or
                        services so obtained/availed of by the Cardmembers under this
                        offer. Any disputes with regard to the quality of
                        goods/services availed shall be taken up with the merchant
                        directly. American Express shall have no liability whatsoever
                        with regard to the same.
                        </li>
                        <li style={{ marginTop: '8px' }}>
                        • American Express shall not be liable whatsoever for any
                        loss/damage/claims that may arise out of use or non-use of
                        any goods or services availed by Cardmember under this offer.
                        American Express and the merchant reserve their absolute
                        right to withdraw and/or alter any of the terms and conditions
                        of the offer at any time without prior notice.
                        </li>
                        <li style={{ marginTop: '8px' }}>
                        • Nothing expressed or implied in the program shall in any
                        way waive or amend any of the Terms and Conditions of the
                        existing Cardmember agreement with the Card issuer.
                        </li>
                        <li style={{ marginTop: '8px' }}>
                        • To receive these offers, purchase should be charged in full
                        to the American Express Card. No cash or other card
                        alternatives are available.
                        </li>
                        <li style={{ marginTop: '8px' }}>
                        • The offer may be subject to merchant and Card issuer's terms
                        and conditions. Please check with the merchant for details
                        and availability before availing the offer.
                        </li>
                        <li style={{ marginTop: '8px' }}> © 2023 Experience Maldives. All rights reserved.</li>
                    </ol>
                    </p>
                    <button onClick={closeModal}>Close</button>
                </div>
            </div>
            )}
        </section>
    );

      


}
