import React, { useEffect } from "react";
import "./footer.css";
import video2 from "../../Assets/video (2).mp4";

import Aos from "aos";
import "aos/dist/aos.css";
import FooterLogo from "../../Assets/mmprc.png";
import FooterLogo2 from "../../Assets/FooterLogo (2).png";
import FooterLogo3 from "../../Assets/FooterLogo (3).png";
import FooterLogo4 from "../../Assets/FooterLogo (4).png";

import BML from "../Navbarscreen/Logo/BML";
import Amex from "../Navbarscreen/Logo/Amex";

const Footer = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <section id="footer" className="footer">
            <div className="videoDiv">
                <video
                    src={video2}
                    loop
                    autoPlay
                    muted
                    type="video/mp4"
                ></video>
            </div>
            <div className="secContent container">
                <div className="footerCard flex">
                    <div className="footerIntro flex">
                        <div className="logoDiv">
                            <a href="#" className="logo flex">
                                <h1>Experience Maldives.</h1>
                            </a>
                        </div>

                        <div
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            className="footerParagraph"
                        >
                            We offer the best Experience for you here in
                            Maldives, Choose what suits you!
                        </div>
                    </div>

                    <div className="footerLinks flex">
                        <div
                            data-aos="fade-up"
                            data-aos-duration="4000"
                            className="linkGroup"
                        >
                            <div className="partnergrp">
                            <span className="Partners">PARTNERS</span>
                            <div className="PartnerLogo">
                                <a href="#" className="LogoDiv FooterLogo"> 
                                    <div className="bml">
                                        <BML />
                                    </div>
                                    <div className="amex">
                                        <Amex />
                                    </div>
                                </a>
                            </div>
                            </div>
                            <div className="endorsegrp">
                            <span className="Endorsed">ENDORSED BY</span>
                            <div className="endorselogo">
                                <img
                                    className="mmprc"
                                    src={FooterLogo}
                                    alt="footer"
                                />
                                <img
                                    className="nbam"
                                    src={FooterLogo2}
                                    alt="footer"
                                />
                                <img
                                    className="mati"
                                    src={FooterLogo3}
                                    alt="footer"
                                />

                            <img
                                    className="tourismlogo"
                                    src={FooterLogo4}
                                    alt="footer"
                                />  
                            </div>
                           
                            </div>
                            </div>
                    </div>

                    <div className="footerDiv flex">
                        <small>EXPERIENCE MALDIVES</small>
                        <small>
                            COPYRIGHTS RESERVED - BANK OF MALDIVES 2023
                        </small>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
